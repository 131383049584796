import React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./components/SidebarMenus/Dashboard/dashboard";
import DNSLogs from "./components/SidebarMenus/DNSQueryLogs/HistororicDNS/dnslog";
import RealtimeDNS from "./components/SidebarMenus/DNSQueryLogs/RealtimeDNS/realtimedns";
import ThreatIntelligence from "./components/SidebarMenus/ThreatIntelligence/threatintelligence";
import Documentation from "./components/SidebarMenus/Documentation/docs";
import Report from "./components/SidebarMenus/Report/report";
import Contactus from "./components/Contactus/Contactus";
import Pagenotfound from "./components/ServerError/pagenotfount";
import Login from './components/Auth/Login/login';
import Forgot from "./components/Auth/Forgot/forgot";
import Resetpassword from "./components/Auth/ResetPassword/Resetpassword";
import Layouts from "./components/Layouts/mainlayout";
import Settings from "./components/SidebarMenus/Settings/Main/settings";
import VerifyEmail from "./components/SidebarMenus/Settings/ChangeEmail/verifyemail";
import Notification from "./components/SidebarMenus/Notification/notifications";
import ProtectedRoutes from "./components/Utils/ProtectedRoutes";
import Recoverpassword from "./components/Auth/Forgot/recoverPassword";
import Selective from "./components/SidebarMenus/Highrisk/Selective/selective";
import HighriskDashboard from "./components/SidebarMenus/Highrisk/Highriskdashboard/highriskdashboard";
// import WBlisting from "./components/SidebarMenus/white-blacklisting/W-Blisting";
import Anomalies from "./components/SidebarMenus/DNSAnomalies/anomalies";
import ThreatAnalysis from "./components/SidebarMenus/DNSInsights/threat";

function App() {
  return (
    <Router>
     <Routes>
          
            <Route path="/" element={<Login/>} /> 
            <Route path="/forgot" element={<Forgot/>} /> 
            <Route path="/recover/password" element={<Recoverpassword/>}/>
          {/* Protected Route */}
          <Route element={<ProtectedRoutes/>}>
              <Route path="/dashboard" element={<Layouts><Home /></Layouts>} /> 
              <Route path="/setnew/password" element={<Resetpassword/>}/>
              <Route path="/anomalies" element={<Layouts><Anomalies/></Layouts>}/>
              <Route path="/dnsinsights" element={<Layouts><ThreatAnalysis/></Layouts>}/>
              <Route path="/dns-querylog/historical" element={<Layouts><DNSLogs/></Layouts>}/>
              <Route path="/dns-querylog/realtime" element={<Layouts><RealtimeDNS/></Layouts>}/>
              <Route path="/highrisk/tldlist" element={<Layouts><Selective/></Layouts>}/>
              <Route path="/highrisk/anomalies" element={<Layouts><HighriskDashboard/></Layouts>}/>
              {/* <Route path="/white-blacklisting" element={<Layouts><WBlisting/></Layouts>}/> */}
              <Route path="/threatintelligence" element={<Layouts><ThreatIntelligence/></Layouts>}/> 
              <Route path ="/docs" element={<Layouts><Documentation/></Layouts>}/>
              <Route path="/report" element={<Layouts><Report /></Layouts>}/>
              <Route path="/settings" element={<Layouts><Settings/></Layouts>}/>
              <Route path="/verify/email" element={<VerifyEmail/>}/>
              <Route path="/notification" element={<Layouts><Notification/></Layouts>}/>
              <Route path="/contact" element={<Contactus />}/>
          </Route>          
          <Route path="/*" element={<Pagenotfound/>}/>  
     </Routes>
    </Router>
  );
}

export default App;




