import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../Assets/protectivedns.png";
import SuccessPage from "../../Modals/sucesspage";
import axios from "../../API/axios";
import ServerError from '../../ServerError/serverdown';
import { AppTitle } from "../../Common/Helmet/helmet";
import Loading from '../../Common/Loading/loading';
import Invalidtoken from '../../ServerError/invalidtoken';
import ErrorToast from "../../Common/Toast/errortoast";


export default function Recoverpassword() {
  const [newPasswordIcon, setNewPasswordIcon] = useState(<IoEyeOffOutline />);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(<IoEyeOffOutline />);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [passwordUpdated, setpasswordUpdated] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&]{8,}$/;
  const [isLoading, setIsLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const navigate = useNavigate();

  const handleisResetPassword = () => {
    navigate('/')
  }

  // App Title
  AppTitle("Recover Password | Protective DNS")

 // Server Down-Error
  const [serverError, setServerError] = useState(false);

 // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

//  Handle Password Match
  const handlePasswordMatch = (e) => {
    e.preventDefault();
      if (newPassword.length === 0 || confirmPassword.length === 0) {
        showErrorToast("Please Provide Passwords!");
      } else if (newPassword !== confirmPassword) {
        showErrorToast("Passwords Do Not Match!");
      } else if (!passwordRegex.test(newPassword)) {
        showErrorToast(
          <>
            Password must contain one lowercase letter, one uppercase letter, one digit,
            <br /> one special character, and be at least 8 character log
          </>
        );} else {
          const currentUrl = new URL(window.location.href);
          const token = currentUrl.searchParams.get('token');
          setIsLoading(true);
          axios.put('/api/v1/recover/password',{
            password: newPassword},
            { headers: {Authorization: `Bearer ${token}`,},
          },{withCredentials: true}).then(function(response){
            setpasswordUpdated(true);
            setIsLoading(false);
         }).catch(function(error){
          if (error.response){
            if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                setInvalidToken(true) 
            } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                setInvalidToken(true) 
            } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                setInvalidToken(true) 
            } else{
                setServerError(true);
            }
          } else{
            setServerError(true);
          }
          setIsLoading(false);
         })
    }
  };
  

// Handle New Password
  const handleNewPassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      setNewPasswordIcon(<IoEyeOutline />);
    } else {
      setNewPasswordType("password");
      setNewPasswordIcon(<IoEyeOffOutline />);
    }
  };

  // Handle Confirm Password
  const handleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIcon(<IoEyeOutline />);
    } else {
      setConfirmPasswordType("password");
      setConfirmPasswordIcon(<IoEyeOffOutline />);
    }
  };

  return (
    <> 
    {isLoading && (<Loading/>)}
    {serverError ?(
      <ServerError/>
      ):(
        <section className="flex items-center justify-center bg-slate-50 bg-cover bg-center bg-no-repeat w-screen h-screen">
     
      {passwordUpdated ? (
        <SuccessPage handleisResetPassword={handleisResetPassword} />
      ) : (
        <>
        
          {invalidToken ? (
            <Invalidtoken/>
          ):(
              <div className="flex flex-col items-center justify-center px-6 py-8 w-1/2 lg:py-0">
                <Link href="#" className="flex items-center mb-6 text-3xl font-semibold text-gray-900">
                <img className="w-10 h-10 mr-2" src={Logo} alt="logo"/>
                Protective DNS   
            </Link>
                <div className="w-full p-6 bg-white rounded-lg shadow-2xl md:mt-0 sm:max-w-md sm:p-8">
                  <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                    Set New Password
                  </h2>
                  <form
                    className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
                    onSubmit={handlePasswordMatch}
                    action="#"
                  >
                  {/* New Password */}
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="newPassword"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <span className="absolute right-3 top-3 w-5 h-5" onClick={handleNewPassword}>
                        {newPasswordIcon}
                      </span>
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <span>
                          <svg
                            className="w-5 h-5 text-gray-800"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"
                            />
                          </svg>
                        </span>
                      </div>
                      <input
                        id="newPassword"
                        type={newPasswordType}
                        className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="New password"
                        required=""
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
      
                  {/* confirm password */}
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="confirmPassword"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <span className="absolute right-3 top-3 w-5 h-5" onClick={handleConfirmPassword}>
                        {confirmPasswordIcon}
                      </span>
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <span>
                          <svg
                            className="w-5 h-5 text-gray-800"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"
                            />
                          </svg>
                        </span>
                      </div>
                      <input
                        id="confirmPassword"
                        type={confirmPasswordType}
                        className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Confirm password"
                        required=""
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
      
                  {/* Reset Passworn Button */}
                  <button type="submit" className="w-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none font-medium rounded-md text-sm px-5 py-2.5 text-center">
                      Reset password
                    </button>
                  </form>
                </div>
                <div className="mt-10 text-sm">
                  © 2024 <Link to='https://shreshtait.com' target="_blank">Shreshta IT Technologies Pvt, Ltd. </Link> All rights reserved.
                </div>
              </div>
          )}
        </>
      )}

       {/* Toast */}
       {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
       )}
    </section>
    )}
    </>
  );
}