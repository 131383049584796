import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Lottie from "lottie-react";
import Loadinganimation  from "../../../Assets/Loading.json";
import axios from "../../API/axios";
import ServerError from '../../ServerError/serverdown';
import { AppTitle } from "../../Common/Helmet/helmet";
import { useNavigate } from "react-router-dom";
import Loading from '../../Common/Loading/loading';
import SuccessToast from "../../Common/Toast/successtoast";
import ErrorToast from "../../Common/Toast/errortoast";

const Report = () => {
  
  // Title
  AppTitle("Report | DNS Shield")
  
    // Server Down-Error
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const maxdate = new Date();
  const [dataformat, setDataFormat] = useState('csv');
  const [querytype, setQueryType] = useState('All');
  const [enableEndDate, setEnableEndDate] = useState(true);
  const [enableStartDate, setEnableStartDate] = useState(false);
  const [valueStartDate, setStartValue] = useState({
    startDate: null,
  });
  const [valueEndDate, setEndValue] = useState({
    startDate: null,
    endDate: null,
  });
  
  // Toast Success
const showSuccessToast = (message) => {
  setToastMessage(message);
    setIsSuccessToast(true);
    setTimeout(() => {
       setIsSuccessToast(false);
     }, 5000);
 };

   // Toast Error
   const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

  // Daily report
  const [isDRChecked, setIsDRChecked] = useState(false);
  const [isDisableSave, setIsDisableSave] = useState(false);

//  Hnadle Dailyreport checkbox
  const handleDRCheckboxChange = (event) => {
    setIsDRChecked(event.target.checked);
    setIsDisableSave(!isDisableSave);
  };

// Handle Start Date
  const handleStartDateChange = (newValue) => {
    setEndValue((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null,
    }));
    setStartValue(newValue);
    setEnableEndDate(false);
  };

  // Handle Date Change
  const handleEndDateChange = (newValue) => {
    setEndValue(newValue);
  };

//Check-Box 
  const [isAllDNSChecked, setAllDNSChecked] = useState(false);
  const [isMaliciousDNSChecked, setMaliciousDNSChecked] = useState(false);

  // Handle DNS or ALL DNS click Change
  const handleDNSChange = (dnsType) => {
    setQueryType(dnsType);
    if (dnsType === "all") {
      setAllDNSChecked(true);
      setMaliciousDNSChecked(false);
    } else if (dnsType === "malicious") {
      setAllDNSChecked(false);
      setMaliciousDNSChecked(true);
    }
  };

  // Radio Button 
  const [isCSVChecked, setCSVChecked] = useState(false);
  const [isPDFChecked, setPDFChecked] = useState(false);
  const [downlaodURL, setdownlaodURL] = useState('');
  const [isURL, setisURL] = useState(false);
 

  // Handle  change File type to download.
  const handleFileTypeChange = (fileType) => {
    setDataFormat(fileType)
    if (fileType === "csv") {
      setCSVChecked(!isCSVChecked);
      setPDFChecked(false);
    } else if (fileType === "pdf") {
      setCSVChecked(false);
      setPDFChecked(!isPDFChecked); 
    }
  };

// Handle generate report 
  const isGenerateReportDisabled = () => {
    return !(
      ((isAllDNSChecked || isMaliciousDNSChecked) && ((enableStartDate) || (valueStartDate.startDate && valueEndDate.endDate)) && (isCSVChecked || isPDFChecked))
    );
  };  
 

// Loading Popup
const [isPopupOpen, setPopupOpen] = useState(false);

// Handle Popup API
const handleGenerateReport = () => {
  setPopupOpen(true);
  if(isAllDNSChecked){
    setEndValue({
    startDate: null,
    endDate: null,
  })
  }
  axios.post("/api/v1/report/data",{
      query_type: querytype,
      startdate: valueStartDate.startDate,
      enddate: valueEndDate.endDate,
      dataformat: dataformat
  },{withCredentials: true}
  ).then(function(response){
    setServerError(false);
     if (response.data.filepath){
        setdownlaodURL(response.data.filepath)
        setisURL(true)
     }
    setPopupOpen(false);
  }).catch(function(error){
    if (error.response){
      if (error.response.data.message){
        showErrorToast(error.response.data.message)
      } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
        navigate("/")
      } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
        navigate("/")
      } else{
        setServerError(true);
      }
    } else{
      setServerError(true);
    }
    setPopupOpen(false);
  })
};

// Downlaod report or generate report API
const handleDownloadReport = (filename) => {
  setServerError(false);
  const url = `http://139.5.190.116/api/v1/report/export?filename=${filename}`;
  
  // Open the URL in a new tab
  window.open(url, '_blank');
  window.location.reload();
};

// const handleDownloadReport = (filename) => {
//   setIsLoading(true);
//   const url = `https://protectivedns.in/api/v1/report/export?filename=${filename}`

//   axios.get(`/api/v1/report/export?filename=${filename}`, { responseType: 'blob' }, { withCredentials: true })
//     .then(response => {
//       const blob = new Blob([response.data]);
//       const url = window.URL.createObjectURL(blob);
      
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', filename); 
//       link.target = '_blank'; 

//       link.click();
//       window.URL.revokeObjectURL(url);

//       setIsLoading(false);
//       window.location.href = '/report'; 
//     })
//     .catch(error => {
//       if (error.response) {
//         if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contain a valid token') {
//           navigate("/");
//         } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired') {
//           navigate("/");
//         } else {
//           setServerError(true);
//         }
//       } else {
//         setServerError(true);
//       }
//       setIsLoading(false);
//     });
// };


// Daily report API


const handleSaveDailyReport = () =>{
  setIsLoading(true);
  axios.put('/api/v1/report/action',
  {
    isreport: isDRChecked
  },{withCredentials: true}).then(function(response){
    setServerError(false);
      if (response.data.message === 'daily report is enabled successfully'){
         showSuccessToast('Daily report is successfully enabled')
      } else if (response.data.message === 'daily report is disabled successfully'){
        showSuccessToast('Daily report is successfully disabled')
      }
      
    ReportState();
    setIsDisableSave(!isDisableSave);
    setIsLoading(false);
     setPopupOpen(false);
   }).catch(function(error){
     if (error.response){
      if(error.response.data.message === 'daily report is failed to enable'){
          showErrorToast('Failed to enable daily report!')
      } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
         navigate("/")
       } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
         navigate("/")
       } else{
         setServerError(true);
       }
     } else{
       setServerError(true);
     }
     setIsLoading(false);
   })
 };

 const ReportState = () => {
  axios.get('/api/v1/report/action'
  ,{withCredentials: true}
  ).then(function(response){
    setServerError(false);
      if (response.data.message === 'daily report is enabled'){
         setIsDRChecked(true);
      } else if (response.data.message === 'daily report is not enabled'){
        setIsDisableSave(false)
      }
     setPopupOpen(false);
   }).catch(function(error){
     if (error.response){
      if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
         navigate("/")
       } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
         navigate("/")
       } else{
         setServerError(true);
       }
     } else{
       setServerError(true);
     }
     setPopupOpen(false);
   })
 }

 useEffect(() =>{
  ReportState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <section className="inline-flex justify-start w-full h-full p-2">
      {isLoading && (<Loading/>)}
        {serverError ?(
        <ServerError/>
        ):(
            <>
            <div className="flex-row-2 w-full space-y-6"> 
            {/* Generate Report */}
            <div className="bg-white w-[58%] h-max pl-10 px-10 shadow-default p-6 rounded-md">
                <h2 className="text-2xl font-bold mb-3">DNS Shield Report</h2>
                <hr className="pb-5 border-gray-300" />
                {/* Radio Button */}
                <div className="flex items-center">
                <div className="inline-flex -ml-4 items-center font-semibold">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer" id="allDNS" >
                    <input type="radio" name="allDNS" checked={isAllDNSChecked} onChange={() => handleDNSChange("all")}
                        className="before:content[''] peer relative h-[22px] w-[22px] cursor-pointer appearance-none rounded-full border-2 border-gray-400 text-primary-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:ring-2 checked:before:ring-primary-500 hover:before:opacity-10"/>
                    <span className="absolute text-primary-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                        </svg>
                    </span>
                    </label>
                    All DNS Queries
                </div>
                <div className="inline-flex items-center font-semibold">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer" id="maliciousDNS">
                    <input type="radio" name="maliciousDNS" checked={isMaliciousDNSChecked} onChange={() => handleDNSChange("malicious")}
                        className="before:content[''] peer relative h-[22px] w-[22px] cursor-pointer appearance-none rounded-full border-2 border-gray-400 text-primary-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:ring-2 checked:before:ring-primary-500 hover:before:opacity-10"/>
                    <span className="absolute text-primary-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
                        <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                        </svg>
                    </span>
                    </label>
                    Malicious DNS Queries
                </div>
                </div>
                {/* Date Picker and Toggle */}
                <div className="mt-4" htmlFor="datepicker">
                <div className="flex items-center">
                    <p className="font-semibold" >Date range:  </p>
                    <div className="justify-end space-x-4 flex-1 flex items-center">
                    <h1 className="font-semibold">All Time Records</h1>
                    <label className="relative inline-flex items-center cursor-pointer" id="toggle">
                        <input name="toggle" type="checkbox" value="" className="sr-only peer" onChange={() => { setEnableStartDate(!enableStartDate);}}/>
                        <div className="w-11 h-6 bg-slate-100 border border-primary-400 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                    </label>
                    </div>
                </div>

                {/* Date picker */}
                <form id="datepicker">
                <div className="flex items-center my-4 space-x-4">
                    <Datepicker
                    id="start-date"
                    name="datepicker"
                    inputClassName="w-full outline-none border border-gray-500  py-2 px-2 focus:border-primary-600 hover:border-primary-600 rounded-md font-normal placeholder:text-gray-500"
                    asSingle={true}
                    value={valueStartDate}
                    maxDate={maxdate}
                    useRange={false}
                    popoverDirection="down"
                    onChange={handleStartDateChange}
                    placeholder={"Start Date"}
                    disabled={enableStartDate}
                    />
                    <p className="font-semibold text-md"> To</p>
                    <Datepicker
                    id="end-date"
                    name="datepicker"
                    inputClassName="w-full outline-none border border-gray-500 py-2 px-2 focus:border-primary-600 hover:border-primary-600 rounded-md font-normal placeholder:text-gray-500"
                    asSingle={true}
                    value={valueEndDate}
                    maxDate={new Date()}
                    minDate={valueStartDate.startDate}
                    useRange={false}
                    popoverDirection="down"
                    onChange={handleEndDateChange}
                    placeholder={"End Date"}
                    disabled={enableEndDate}
                    />
                </div>
                </form>
                </div>
                {/* Checkbox 1 */}
                <div className="flex space-x-3">
                <div className="inline-flex items-center">
                    <label id="csv" className="relative flex items-center p-[1px]  rounded-md cursor-pointer hover:ring-2 hover:ring-primary-500">
                    <input type="checkbox" name="csv" checked={isCSVChecked}  onChange={() => handleFileTypeChange('csv')} className="before:content[''] peer relative h-5 w-5 border-gray-500 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:bg-primary-500 checked:before:bg-primary-500 hover:border-primary-500"/>
                    <span className="absolute text-white  transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </span>
                    </label>
                </div>
                <h1 className="font-semibold">CSV</h1>
                </div>
                {/* Checkbox 2 */}
                <div className="flex space-x-3 mt-2">
                <div className="inline-flex items-center">
                    <label className="relative flex items-center p-[1px]  rounded-md cursor-not-allowed" id="pdf">
                    <input type="checkbox"
                        name="pdf"
                        // checked={isPDFChecked}
                        // onChange={() => handleFileTypeChange('pdf')}
                        className="before:content[''] peer relative h-5 w-5 border-gray-300 cursor-not-allowed appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity"
                    />
                    <span className="absolute text-white  transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </span>
                    </label>
                </div>
                <h1 className="font-semibold text-gray-300">PDF (coming soon)</h1>
                </div>
                {/* <div className="flex space-x-3 mt-2">
                <div className="inline-flex items-center">
                    <label className="relative flex items-center p-[1px]  rounded-md cursor-not-allowed hover:ring-2 hover:ring-primary-500" htmlFor="pdf">
                    <input type="checkbox"
                        checked={isPDFChecked}
                        onChange={() => handleFileTypeChange('pdf')}
                        className="before:content[''] peer relative h-5 w-5 border-gray-300 cursor-not-allowed appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:bg-primary-500 checked:before:bg-primary-500 hover:border-primary-500"
                    />
                    <span className="absolute text-white  transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </span>
                    </label>
                </div>
                <h1 className="font-semibold text-gray-300">PDF</h1>
                </div> */}
                {/* Footer Buttons */}
                <div className="flex my-5 w-full space-x-3 justify-end">
                <div style={{ display: "flex", alignItems: "center" }}>
                    {isURL &&
                    <button onClick={() => handleDownloadReport(downlaodURL)} className=" flex bg-primary-500 hover:bg-primary-600 shadow-[0_3px_10px_rgb(0,0,0,0.2)] outline-none py-2 px-4 text-white rounded-md font-semibold">
                    Download Report
                    <span className=" pl-2">
                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12V19M12 19L9.75 16.6667M12 19L14.25 16.6667M6.6 17.8333C4.61178 17.8333 3 16.1917 3 14.1667C3 12.498 4.09438 11.0897 5.59198 10.6457C5.65562 10.6268 5.7 10.5675 5.7 10.5C5.7 7.46243 8.11766 5 11.1 5C14.0823 5 16.5 7.46243 16.5 10.5C16.5 10.5582 16.5536 10.6014 16.6094 10.5887C16.8638 10.5306 17.1284 10.5 17.4 10.5C19.3882 10.5 21 12.1416 21 14.1667C21 16.1917 19.3882 17.8333 17.4 17.8333" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    </button>}
                </div>

                <button onClick={handleGenerateReport} disabled={isGenerateReportDisabled()} className={`bg-primary-500 hover:bg-primary-600 shadow-[0_3px_10px_rgb(0,0,0,0.2)] outline-none py-2 px-4 text-white rounded-md font-semibold
                    ${isGenerateReportDisabled() && "opacity-50 cursor-not-allowed"}`}>
                    Generate Report
                </button>
                </div>
                {/* Popup */}
                {isPopupOpen && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity flex items-center justify-center">
                    <div className="w-2/6 relative rounded-lg  bg-white ">
                    <div>
                        <div className="text-center">
                        <Lottie
                            animationData={Loadinganimation}
                            loop={true}
                            className="-mt-36"
                        />
                        <h3 className="text-xl font-bold">
                            Please wait...
                        </h3>
                        <p className="text-xl font-bold pb-14"> while your report is being prepared.</p>
                        </div>
                    </div>
                    </div>
                </div>
                )}
            </div>
            {/* Daily Report */}
            <div className="bg-white w-[58%] h-max pl-10 px-10 shadow-default p-6 rounded-md">
                <h2 className="text-2xl font-bold mb-3">Daily Report</h2>
                <hr className="pb-5 border-gray-300" />
            
                {/* Checkbox 1 */}
                <div className="flex space-x-3">
                <div className="inline-flex items-center">
                    <label id="csv" className="relative flex items-center p-[1px]  rounded-md cursor-pointer hover:ring-2 hover:ring-primary-500">
                    <input type="checkbox" name="yes" checked={isDRChecked} onChange={handleDRCheckboxChange} className="before:content[''] peer relative h-5 w-5 border-gray-500 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:bg-primary-500 checked:before:bg-primary-500 hover:border-primary-500"/>
                    <span className="absolute text-white  transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </span>
                    </label>
                </div>
                <h1 className="font-semibold">Receive a copy of the daily report by email.</h1>
                </div>

                {/* Footer Buttons */}
                <div className="flex my-5 w-full space-x-3 justify-end">
                <button onClick={handleSaveDailyReport}  className={`bg-primary-500 hover:bg-primary-600 shadow-[0_3px_10px_rgb(0,0,0,0.2)] outline-none py-2 px-[60px] text-white rounded-md font-semibold ${!isDisableSave && 'opacity-50 cursor-not-allowed'}`}>
                Save
                </button>
                </div>
            </div>
        </div>
        </>)}
        {/* Toast */}
        {isSuccessToast && (
          <SuccessToast toastMessage={toastMessage}/>
        )}
        {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
        )}
    </section>
  );
};

export default Report;
