import React, { useState,useEffect, useRef } from "react";
import { IoEyeOutline,IoEyeOffOutline } from "react-icons/io5";
import {Link, useNavigate} from "react-router-dom";
import Logo from "../../../Assets/protectivedns.png";
import axios from "../../API/axios";
import ServerError from '../../ServerError/serverdown';
import { AppTitle } from "../../Common/Helmet/helmet";
import Loading from '../../Common/Loading/loading';
import ErrorToast from "../../Common/Toast/errortoast";
import ReCAPTCHA from "react-google-recaptcha";
import Resetpassword from "../ResetPassword/Resetpassword";


export default function Login() {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [passwordType, setPasswordType] = useState('password')
  const [passwordIcon, setPasswordIcon] = useState(<IoEyeOffOutline/>);
  const [isLoading, setIsLoading] = useState(false);
  const [isTwofactor, setIsTwofactor] = useState(false);
  const [recoveryKey, setRecoveryKey] = useState('');
  const [isRecoveryKey, setIsRecoveryKey] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const reCaptchaRef = useRef(null);

   // Title
   AppTitle("Login | DNS Shield")

  const handleisResetPassword = () => {
    setIsResetPassword(false);
  } 

  const handleRecoveryToggle = () => {
    setIsRecoveryKey(!isRecoveryKey);
  }

 
  // Server Down-Error
  const [serverError, setServerError] = useState(false);

  // password input eye toggle
  const navigate = useNavigate();
  const handleToggle =()=>{
    if(passwordType === 'password'){
      setPasswordType('text')
      setPasswordIcon(<IoEyeOutline/>)
    } else {
      setPasswordType('password')
      setPasswordIcon(<IoEyeOffOutline/>)
    }
  }


  // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

  // Stats API
  useEffect(() => {
    axios.get("/api/v1/auth/state", { withCredentials: true })
      .then(function (response) {
        if (response.data.message === 'verify two factor authentication' && response.data.twofactor === true && response.status === 200){
            setIsTwofactor(true);
        } else if (response.data.message === 'please set new password' && response.data.first_login === true && response.status === 200){
          setIsResetPassword(true);
        } else if(response.data.message === 'user is authenticated' && response.status === 200){  
          navigate('/dashboard');
        }
      })
      .catch(function (error) {
        if (error.response){
          if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
          }else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          } else{
            navigate("/")
          }
        } else{
          navigate("/")
        }
      });
  }, [navigate]);

  const handleRecaptcha = (e) =>{
    e.preventDefault();
    if (username.length === 0) {
      showErrorToast("Please Provide username !");
    } else if (password.length === 0) {
      showErrorToast("Please provide password !");
    } else {
      reCaptchaRef.current.execute();
    }
  }

  const onChange = (token) => {
    setIsLoading(true)
        axios.post('/api/v1/verifycaptcha', {
            token: token,
          },{ withCredentials: true })
          .then(function (response){
          if (response.data.message === "reCaptcha is verified" && response.status === 200){
            handleSignIn();
          }   
        })
        .catch(function (error){
          reCaptchaRef.current.reset();
          setIsLoading(false)
          setServerError(true);
        });
  };

 //Captcha Site Key  
  const RECAPTCHA_SITE_KEY = "6LeUCfMpAAAAAOdS-EkrbRnBiiJxGyiuUxzUJrA1";

  // Handle Sign in and API
  const handleSignIn = () => {   
      setIsLoading(true);
       axios.post('/api/v1/login',{
        username: username,
        password: password
       },{withCredentials: true}
       ).then(function(response){
        if (response.data.message === "verify two factor authentication" && response.status === 202){
            setIsTwofactor(true)
        } else if (response.data.message === "set new password" && response.status === 202) {
          setIsResetPassword(true);
        } else if (response.data.message === "user is successfully logged in" && response.status === 202) {
            navigate('/dashboard')
        }
        setusername('');
        setPassword('');
        setIsLoading(false);
       }).catch(function(error){
        reCaptchaRef.current.reset();
        if (error.response){
          if (error.response.data.message === "your account is expired" && error.response.status === 403){
            showErrorToast("Your account is expired!");
          } else if (error.response.status === 401){
            showErrorToast("Invalid credentials!");
          } else {  
            setServerError(true);
          }
        }
        setIsLoading(false);
       })
    
  };
  
const handleKeyDownOnInput = (e) => {
    if (e.key === 'Enter') {
      // Trigger click event on the "Sign in" button
      handleRecaptcha(e);
    }
  };

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  let newOtp

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Move to next input if current field is filled
    if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };


  // Verify2FA  API
  const Verify2fa = () => {
    const combinedOtp = otp.join("")
    if (combinedOtp.length === 0) {
      showErrorToast('Please enter code!');
    }else if (combinedOtp.length < 6) {
      showErrorToast('Please enter a valid OTP and try again.');
    } else {
      setIsLoading(true);
      axios.post('/api/v1/twofactor/verify',{otpcode: combinedOtp}
      ,{withCredentials: true})
        .then(function (response) {
          if (response.data.message === 'otp is verified successfully' && response.status === 202){
              navigate('/dashboard');
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
              showErrorToast("Too many request try after 1 hour");
            } else if (error.response.status === 401 && error.response.data.message === 'invalid otp') {
              showErrorToast('Invalid OTP. Please try again.'); 
            } else if (error.response.status === 401 && (error.response.data.message === 'please enable two factor' || error.response.data.message === 'unauthorized access')){
              setIsTwofactor(false);
              showErrorToast('Unauthorized access');          
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
              navigate("/")
              setIsErrorToast('Token expired!');
            } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
              navigate("/")
              setIsErrorToast('Token expired!');
            } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
              navigate("/")
              setIsErrorToast('Token expired!');
            } else {
              setServerError(true);
            }
            setIsLoading(false);
          }
        });
    }
  }

  const handleVerifyKey = () => {
    if (recoveryKey.length === 0) {
      showErrorToast("Please enter recovery key!");
    } else {
      setIsLoading(true);
      axios.put('/api/v1/twofactor/verify/recoverykey',{
        recoverykey: recoveryKey
      },{withCredentials: true})
        .then(function (response) {
          if (response.data.message === 'recovery key is successfully verified' && response.status === 200){
              navigate('/dashboard');
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
              showErrorToast("Too many request try after 1 hour");
            } else if (error.response.status === 400 && error.response.data.message === 'invalid recovery key') {
              showErrorToast('Invalid recovery key. Please try again.'); 
            } else if (error.response.status === 401 && (error.response.data.message === 'please enable two factor' || error.response.data.message === 'unauthorized access')){
              setIsTwofactor(false);
              showErrorToast('Unauthorized access');          
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
              navigate("/")
              setIsErrorToast('Token expired!');
            } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
              navigate("/")
              setIsErrorToast('Token expired!');
            } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
              navigate("/")
              setIsErrorToast('Token expired!');
            } else {
              setServerError(true);
            }
            setIsLoading(false);
          }
        });
    } 
  };

  return (
    <>{isResetPassword ? (
      <Resetpassword handleisResetPassword={handleisResetPassword}/>
    ):(
      <section className="flex items-center justify-center bg-slate-50 bg-cover bg-center bg-no-repeat w-screen h-screen">
      {isLoading && (<Loading/>)}
     {serverError ?(
      <ServerError/>
      ):(
        <>
      <div className="flex flex-col items-center justify-center w-full">
      {/* DNS Shield Logo */}
      <Link href="#" className="flex items-center mb-6 text-3xl  text-black font-bold">
          <img className="w-10 h-10 mr-2" src={Logo} alt="logo"/>
          DNS Shield   
      </Link>
        {isTwofactor ? (
            <>
            {/* Recoverykey */}
            {isRecoveryKey ? (
              <div className="w-full p-6 bg-white shadow-2xl rounded-lg  md:mt-0 sm:max-w-md sm:p-8">
              <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Recovery Code
              </h1>
              <p className="font-light text-gray-700">
              In case your device is misplaced or stolen, enter the recovery code below.
              </p>
              <div className="mt-5 space-y-5">
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute top-[14px] h-full w-10 text-gray-400">
                    <svg className="w-6 h-6 text-gray-800" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.0667 5C21.6586 5.95805 22 7.08604 22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.272 15.465 11.8598 15.465 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876C6.22633 20.6364 5.952 20.9096 5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C16.4995 2 17.282 2.15509 18 2.43738" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.8851 8.29353C17.8851 9.50601 16.8982 10.4889 15.6807 10.4889C14.4633 10.4889 13.4763 9.50601 13.4763 8.29353C13.4763 7.08105 14.4633 6.09814 15.6807 6.09814C16.8982 6.09814 17.8851 7.08105 17.8851 8.29353Z" stroke="#1C274C" stroke-width="1.5"/>
                    </svg>
                  </div>
                  <input
                    className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                    placeholder="Enter the recovery code" required="" id="email" type="text" autoComplete="off" autoFocus value={recoveryKey} onChange={(e) => setRecoveryKey(e.target.value)} />
                </div>
              
                {/*Verify Key butoon */}
                <button onClick={handleVerifyKey} id="verifybutton" className="text-white w-full mx-auto bg-sky-400 outline-none font-medium rounded-lg py-2 text-center">
                  Verify
                </button>
              </div>
              <div className='flex justify-center'>
                        <div onClick={handleRecoveryToggle} className='cursor-pointer text-primary-400 font-semibold mt-4'>Back to two-factor</div>
              </div>
              </div>
            ):(
              // Verify Twofactor
              <div className="flex flex-col items-center justify-center p-8 rounded-lg shadow-2xl bg-white">
                  <div className="flex flex-col space-y-4 items-center justify-center">
                      <h3 className="text-2xl font-bold text-gray-900">
                          Verify Two-Factor Authentication
                      </h3>
                      
                      <p className="leading-relaxed text-gray-700 text-center">
                      For additional security, enter the verification code<br/>  from your two-factor authentication app to login.
                      </p>
                  </div>
                    <div>
                    {otp.map((value, index) => {
                              return (
                                <input
                                autoFocus={index === 0}
                                key={index}
                                type="text"
                                ref={(input) => (inputRefs.current[index] = input)}
                                value={value}
                                onChange={(e) => handleChange(index, e)}
                                onClick={() => handleClick(index)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                
                                className="w-12 h-12 outline-none text-center border rounded-md shadow-sm my-5 focus:border-primary-500 mx-2"
                              />                              
                              );
                    })}
                    </div>
                  <button onClick={Verify2fa} className="text-white w-full mx-auto bg-sky-400 outline-none font-medium rounded-lg py-3 text-center">Verify code</button>
                  <div className='flex justify-center'>
                      <div onClick={handleRecoveryToggle} className='cursor-pointer text-primary-400 font-semibold mt-4'>Unable to sign in?</div>
                  </div>
              </div>
            )}
            </>
        ):(
        // Login Page
        <div className="w-[27%] bg-white rounded-lg shadow-2xl pb-4">
          <div className="p-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in
            </h1>
            <form className="space-y-5 mt-6" onKeyDown={handleKeyDownOnInput}>
              {/* USer Name */}
              <div className="flex flex-col mb-6">
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">
                  Username
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg class="w-7 h-7 text-gray-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor"  d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                  </svg>
                  </div>
                  <input type="text" id="username" className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                    placeholder="username"
                    autoFocus
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              {/* Password */}
              <div className="flex flex-col mb-6">
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  Password
                </label>
                <div className="relative">
                <span className="absolute right-3 top-3 w-5 h-5 cursor-pointer" onClick={handleToggle}>{passwordIcon}</span>
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400 ">
                    <span>
                    <svg className="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"  d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"/>
                    </svg>
                    </span>
                  </div>

                  <input
                    type={passwordType}
                    id="password"
                    className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="on" 
                    required
                  />
                </div>
              </div>
              <div className="flex items-center justify-end">
                <Link to='/forgot'
                  className="text-sm font-medium -mt-2 text-primary-600 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
            </form>            
           {/* Sign in Button */}
           
          {/* Reacaptcha */}
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={onChange}
            size="invisible"
            ref={reCaptchaRef}
          />
            <button
              id="signInButton"
              type="submit"
              className="w-full text-white mt-4 bg-primary-500 hover:bg-primary-700 focus:outline-none font-medium rounded-lg text-sm px-5 py-3 text-center"
              onClick={handleRecaptcha}
            >
              Sign in
            </button>
          </div>
        </div>)}

        {/* Footer */}
        <div className="mt-10 text-sm font-semibold text-white">
          © 2024 <Link to='https://shreshtait.com' target="_blank">Shreshta IT Technologies Pvt, Ltd. </Link> All rights reserved.
        </div>
      </div>

    {/* Toast */}
     {isErrorToast && (
       <ErrorToast toastMessage={toastMessage}/>
         )}
 </>)}
    </section>
    )}
    </>
  );
}
