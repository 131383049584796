import React, { useState } from 'react'
import Navbar from './navbar'
import Sidebar from './sidebar'

export default function MainLayout({children}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <div className='w-screen h-screen overflow-hidden flex flex-col bg-slate-100'>
      <div>
         <Navbar toggleSidebar={toggleSidebar}/>
      </div>
      <div className='w-full h-full overflow-hidden flex'>
        <div>  
          {isSidebarOpen && <Sidebar/>}
        </div>
        <div className='w-full h-full flex-grow overflow-y-auto overflow-x-hidden'>
          {children}
        </div>
      </div>
    </div>
  )
}
