// DNSAnomaliesChart.jsx
import React from 'react';
import ReactApexChart from 'react-apexcharts';

function DnsAnomaliesChart({
  barData,
  optionstimestamp,
  optionright, 
  optiontimestamp, 
  isDropdownOpenRight, 
  isDropdownOpenTimestamp, 
  dropdownRefRight, 
  handleOptionClickRight, 
  handleOptionClickTimestamp, 
  handleOptionClickRightButton, 
  handleOptionTimestamp, 
  determineAnomalies, 
})
 {
  const series = [
    {
      name: 'Queries',
      data: determineAnomalies(),
    },
  ];

  // Chart options
  const options = {
    chart: {
      id: 'dnsshield_anomaly_report',
      type: 'bar',
      height: 400,
      zoom: {
        autoScaleYaxis: true
      },
      animations: {
        enabled: true
      }
    },
    toolbar: {
      export: {
        png: {
          filename: 'dnsshield_anomaly_report',
        },
        csv: {
          filename: 'dnsshield_anomaly_report',
          headerCategory: 'Date;Time',
          xaxis: {
            type: 'Timestamp',
          },
        },
        svg: {
          filename: 'dnsshield_anomaly_report',
        },
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: optiontimestamp === 'Last 6 months' ? 'MMM yyyy' : 'dd MMM yyyy'
      }
    },
    fill: {
      colors: ['#007bff'],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };

  return (
    <div className="h-full">
      <div className=" bg-white rounded-md shadow-default">
        <div className='flex flex-col-2 justify-between px-2'>
          {/* Date Picker*/}

          <div className="text-2xl font-bold text-gray-900 py-2.5 mt-1 pl-2">
            Anomaly DNS Traffic
          </div>
          <div className="py-2">
            {/* Right Dropdown */}
            <div className="relative" ref={dropdownRefRight}>
              <button onClick={handleOptionClickRight} className="flex font-semibold space-x-3 py-3 px-4  text-gray-800 bg-white rounded-md shadow-default cursor-pointer">
                <span>{optionright}</span>
                <svg className="w-[14px] h-[12px] mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                </svg>
              </button>
              {isDropdownOpenRight && (
                <ul className="absolute top-12 z-50 mt-1 right-[2px] bg-white rounded-md shadow-default px-4 py-3 w-max">
                  <div className="flex pb-4 gap-4">
                    <li className="dropdown">
                      <p className="py-2 pl-2 block whitespace-no-wrap font-semibold">Timestamp:</p>
                      <button onClick={handleOptionClickTimestamp} className="flex w-max font-semibold space-x-3 px-4 py-2  text-gray-800 bg-white rounded-md shadow-default cursor-pointer">
                        <span>{optiontimestamp}</span>
                        <svg className="w-[14px] h-[12px] mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                        </svg>
                      </button>
                      {isDropdownOpenTimestamp && (
                        <ul className="dropdown-content absolute z-50 ml-2 text-gray-700 mt-1 bg-white rounded-md shadow-default cursor-pointer">
                          {optionstimestamp.map((option) => (
                            <p key={option} className="px-5 py-2 font-semibold hover:bg-slate-50 hover:text-primary-500 cursor-pointer" onClick={() => { handleOptionTimestamp(option); } }>
                              {option}
                            </p>
                          ))}
                        </ul>)}
                    </li>
                  </div>
                  <button className="py-2 text-center w-full shadow-lg bg-primary-500 border-none rounded-lg text-white font-semibold" onClick={handleOptionClickRightButton}>Done</button>
                </ul>)}
            </div>
          </div>
        </div>
        <hr className="border-slate-300" />
        {barData.length > 0 ? (
          <div id="chart" className="w-full h-full">
            <ReactApexChart options={options} series={series} type="bar" height={500} />
          </div>
        ) : (
          <p className="text-gray-800 text-center py-28 font-semibold">
            No data
          </p>
        )}
      </div>
    </div>
  );
}

export default DnsAnomaliesChart;
