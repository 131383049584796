
import Stats from './stats';
import { AppTitle } from '../../Common/Helmet/helmet';


export default function ThreatIntelligence() {
   // Title//
   AppTitle("Threat Intelligence | DNS Shield");

  return (
    <section className='w-full h-full space-y-3 p-2'>
      <Stats/>
      <div className='pt-[1px]'></div>
      </section>
  )
}
