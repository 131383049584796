import React from 'react'

export default function SuccessToast({toastMessage}) {
  return (
    <div className="fixed flex items-center justify-center top-2 left-1/2 transform -translate-x-1/2 rounded-md px-4 py-2 transition bg-green-100/90">
        <svg fill="#15803D" className="mr-2" width="27px" height="27px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path className="clr-i-outline clr-i-outline-path-1" d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z"></path>
                    <path className="clr-i-outline clr-i-outline-path-2" d="M28,12.1a1,1,0,0,0-1.41,0L15.49,23.15l-6-6A1,1,0,0,0,8,18.53L15.49,26,28,13.52A1,1,0,0,0,28,12.1Z">
                    </path>
                <rect x="0" y="0" width="27" height="27" fillOpacity="0"/> 
        </svg>
        <p className="text-gray-950 font-medium">{toastMessage}</p>
    </div>
  )
}
