import React from "react";

export default function Stats({intel,tcount,mcount,mode,avg_query}) {

  const getNumber = (num) => {
    var units = ["", "K", "M", "B", "T", "Q"];
    var unit = (num / 1.0e+1).toFixed(0).toString().length;
    var r = unit % 3;
    var x = Math.abs(Number(num)) / Number('1.0e+' + (unit - r)).toFixed(0);
    return Math.round(x) + ' ' + units[Math.floor(unit / 3)];
}

  return (
    <div className="w-full bg-slate-100">
      <div className="grid grid-cols-5 gap-4">

        {/* Stat-1 */}
        <div className="flex flex-col w-full bg-white shadow-default p-6 rounded-lg items-center justify-center">
            <div className="text-2xl text-gray-800 font-semibold mb-1 text-center">Threat intel count</div>
              <div className="text-4xl text-gray-800 font-bold mb-3 mt-1">{getNumber(intel)}+</div>
        </div>

        {/* Stat-2 */}
        <div className="flex flex-col w-full bg-white shadow-default p-6 rounded-lg items-center justify-center">
            <div className="text-2xl text-gray-800 font-semibold mb-1 text-center">DNS queries</div>
            <div className="text-4xl text-gray-800 font-bold mb-3 mt-1">{tcount}</div>
        </div>
        {/* Stat-3 */}
        <div className="flex flex-col w-full bg-white shadow-default p-6 rounded-lg items-center justify-center ">
            <div className="text-2xl text-gray-800 font-semibold mb-1 text-center">Malicious DNS queries</div>
              <div className="text-4xl text-gray-800 font-bold mb-3 mt-1">{mcount}</div>
        </div>
        {/* Stat-4 */}
        <div className="flex flex-col w-full bg-white shadow-default p-6 rounded-lg items-center justify-center ">
            <div className="text-2xl text-gray-800 font-semibold text-center">DNS Shield</div>
            <div className="text-3xl font-bold text-red-500 capitalize">{mode}</div>
            <div className="text-2xl text-gray-800 font-semibold mb-1 text-center"> Mode</div>
        </div>
        {/* Stat-5 */}
        <div className="flex flex-col w-full bg-white shadow-default p-6 rounded-lg items-center justify-center ">
          <div className="text-2xl text-gray-800 font-semibold mb-1 text-center">Queries per second</div>
          <div className="text-4xl text-gray-800 font-bold mb-3 mt-1">
              <p>{avg_query}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
