// header.jsx
export default function Header({
  optionsleft,
  optionstimestamp,

  optionleft,
  optionright,
  optiontimestamp,
  
  isDropdownOpenLeft,
  isDropdownOpenRight,
  isDropdownOpenTimestamp,

  dropdownRefLeft,
  dropdownRefRight,

  handleOptionClickLeft,
  handleOptionClickRight,
  handleOptionClickTimestamp,
  handleOptionClickRightButton,

  handleOptionLeft,
  handleOptionTimestamp,


  setSearchQuery,
  searchquery,
  handleSearch,
  searchPlaceholder,
  handleKeyPress
}) {
  return (
    <section className="flex items-center space-x-4">
      {/* Left Dropdown */}
      <div ref={dropdownRefLeft} className="relative">
        <div className="flex font-semibold  -left-1 space-x-3 py-3 px-4 text-gray-800 bg-white rounded-md shadow-default cursor-pointer" onClick={handleOptionClickLeft}>
          <button>{optionleft}</button>
          <svg className="w-[14px] h-[12px] mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
          </svg>
        </div>
        {isDropdownOpenLeft && (
          <div className="z-50 mt-1 -left-1 text-gray-800 absolute rounded-md overflow-hidden bg-white w-48 shadow-default">
            {optionsleft.map((option) => (
              <p
                key={option}
                className="pl-5 py-2 font-semibold hover:bg-slate-50 hover:text-primary-500 cursor-pointer"
                onClick={() => handleOptionLeft(option)}
              >
                {option}
              </p>
            ))}
          </div>
        )}
      </div>

      {/* Search Bar*/}
      <div className="flex-1 h-12 rounded-md bg-white overflow-hidden relative outline-none shadow-[0px_0px_0px_1px_#f7fafc]" id='text'>
        <input
          className="peer h-full w-full outline-none bg-transparent rounded-md border-primary-500 border text-base text-gray-700 pl-12 pr-2"
          type="text"
          placeholder={searchPlaceholder}
          autoFocus
          value={searchquery}
          onChange={(e)=>setSearchQuery(e.target.value)}
          id='text'
          autoComplete="off"
          onKeyPress={handleKeyPress} 
        />
        <div className="ml-3 w-12 absolute top-3 text-gray-400 hover:text-primary-500 focus:text-primary-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 bg-transparent"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
      </div>

      {/* Search Button */}
      <button onClick={handleSearch} className="p-4 bg-primary-500 rounded-md overflow-hidden text-white text-lg py-2.5 px-16 font-bold hover:bg-primary-600 shadow-[0px_0px_0px_2px_#f7fafc]">
        Search
      </button>

      {/* Right Dropdown */}
      <div className="relative" ref={dropdownRefRight}>
        <button onClick={handleOptionClickRight} className="flex font-semibold space-x-3 py-3 px-4  text-gray-800 bg-white rounded-md shadow-default cursor-pointer">
          <span >{optionright}</span>
          <svg className="w-[14px] h-[12px] mt-2"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                </svg>
        </button>
        {isDropdownOpenRight && (
        <ul className="absolute top-12 z-50 mt-1 right-[2px] bg-white rounded-md shadow-default px-4 py-3 w-max">
          <div className="flex pb-4 gap-4">
            <li className="dropdown">
                  <p className="py-2 pl-2 block whitespace-no-wrap font-semibold">Timestamp:</p>
                  <button onClick={handleOptionClickTimestamp} className="flex w-max font-semibold space-x-3 px-4 py-2  text-gray-800 bg-white rounded-md shadow-default cursor-pointer">
                        <span>{optiontimestamp}</span>
                        <svg className="w-[14px] h-[12px] mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                  </svg>
                  </button>
                  {isDropdownOpenTimestamp && (
                  <ul className="dropdown-content absolute z-50 ml-2 text-gray-700 mt-1 bg-white rounded-md shadow-default cursor-pointer">
                    {optionstimestamp.map((option) => (
                      <p
                        key={option}
                        className="px-5 py-2 font-semibold hover:bg-slate-50 hover:text-primary-500 cursor-pointer"
                        onClick={() => {
                          handleOptionTimestamp(option);
                        }}
                      >
                        {option}
                      </p>
                    ))}
                  </ul>)}
            </li>
          </div>
          <button className="py-2 text-center w-full shadow-lg bg-primary-500 border-none rounded-lg text-white font-semibold" onClick={handleOptionClickRightButton}>Done</button>
        </ul>)}
      </div>
    </section>
  );
}
