import React from "react";
import Toastwarning from "../../Common/Toast/Toastwarning";

export default function Table2({
  currentTTPage,
  ttData,
  hasTableTTData,
  jumpToPage,
  setJumpToPage,
  handleTTPageChange,
  totalTTPages,
  handleJumpToPage,
  Toast,
  Toastmessage,
  handletoast,
  hasTableTDData,
  tdData,
  currentTDPage,
  totalTDPages,
  handleTDPageChange,
}) {
  return (
    <div className="flex justify-center space-x-3 w-full max-h-max">
      
      {/* Table Top Domain */}
      <div className="w-[65%] sm:w-[60%]">
        <h5 className="text-xl font-bold text-gray-900 pb-3 pl-4">Most Frequently Accessed Domain</h5>
        <div className="w-full pb-4 bg-white rounded-md shadow-default overflow-hidden">
          {hasTableTDData? (
            <>
              <div className="overflow-x-auto sm:overflow-hidden">
                <table className="w-full text-sm sm:text-base text-center border-b border-gray-300 rtl:text-center text-gray-800 dark:text-gray-400 bg-white dark:bg-gray-700">
                  <thead className="text-lg text-slate-50 capitalize bg-primary-500 font-semibold">
                    <tr>
                      {/* Table Head */}
                      <th scope="col" className="px-4 py-3"> Domain Name</th>
                      <th scope="col" className="px-4 py-3">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Table Body */}
                  {tdData.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>    
                      <td className="px-4 py-4 ">{item.domain}</td>
                      <td className="px-4 py-4 ">{item.count}</td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
              <div className="pagination-container mt-4 m-2 flex flex-col sm:flex-row justify-between items-center">
                <div className="pagination-buttons flex items-center">
                  {/* Left Pagination of Top Domain */}
                  <button onClick={() =>  handleTDPageChange(currentTDPage - 1)} disabled={currentTDPage === 1}
                    className={` px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                      currentTDPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                    }`}>
                    <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                    </svg>
                  </button>
                  <span className="mx-2 text-gray-800">{`Page ${currentTDPage} of ${totalTDPages}`}</span>
                  {/* Right Pagination of Top Domain */}
                  <button
                    className={`px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                      currentTDPage === totalTDPages
                        ? "text-white cursor-not-allowed"
                        : "text-white cursor-pointer "
                    }`}
                    onClick={() =>  handleTDPageChange(currentTDPage + 1)}
                    disabled={currentTDPage === totalTDPages}>
                    <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
                    </svg>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <p className="text-gray-800 text-center py-4">No data</p>
          )}
        </div>
      </div>

      {/* Table Top Talkers */}
      <div className="w-[35%] sm:w-[40%]">
        <h5 className="text-xl font-bold text-gray-900 pb-3 pl-4">Top Talkers</h5>
        <div className="w-full pb-4 bg-white rounded-md shadow-default overflow-hidden">
          {hasTableTTData ? (
            <>
              <div className="overflow-x-auto sm:overflow-hidden">
                <table className="w-full text-sm sm:text-base text-center border-b border-gray-300 rtl:text-center text-gray-800 dark:text-gray-400 bg-white dark:bg-gray-700">
                  <thead className="text-lg text-slate-50 capitalize bg-primary-500  font-semibold">
                    <tr>
                      {/* Table Head */}
                      <th scope="col" className="px-4  py-3">Source IP</th>
                      <th scope="col" className="px-4  py-3">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Table Data */}
                  {ttData.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>    
                      <td className="p-4">{item.source_ip}</td>
                      <td className="p-4">{item.count}</td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
              <div className="pagination-container mt-4 m-2 flex flex-row justify-between items-center">
                <div className="pagination-buttons flex items-center">
                  {/* left Pagination Of Top Talker */}
                  <button
                    onClick={() =>  handleTTPageChange(currentTTPage - 1)}
                    disabled={currentTTPage === 1}
                    className={` px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                      currentTTPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                    }`}>
                    <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                    </svg>
                  </button>
                  <span className="mx-2 text-gray-800">{`Page ${currentTTPage} of ${totalTTPages}`}</span>
                  {/* Right Pagination od Top Talker */}
                  <button
                    className={`px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                      currentTTPage === totalTTPages
                        ? "text-white cursor-not-allowed"
                        : "text-white cursor-pointer "
                    }`}
                    onClick={() =>  handleTTPageChange(currentTTPage + 1)}
                    disabled={currentTTPage === totalTTPages}>
                    <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
                    </svg>
                  </button>
                </div>
                {/* Page Jump */}
                <div className="jump-to-page flex items-center">
                  <span className="text-gray-700 mr-2" id='number'>Jump to Page:</span>
                  <input  id='number' type="number" className="py-1 px-2 w-12 border border-slate-400 rounded-md" value={jumpToPage} onChange={(e) => setJumpToPage(e.target.value)} />
                  <button className="px-2 py-1 ml-2 bg-primary-500 text-white rounded-md hover:bg-primary-600" onClick={handleJumpToPage}>
                    Go
                  </button>
                </div>
              </div>
            </>
          ) : (
            <p className="text-gray-800 text-center py-4 ">No data</p>
          )}
          {/* Toast */}
          {Toast && <Toastwarning className="ml-auto" message={Toastmessage} handletoast={handletoast} />}
        </div>
      </div>
    </div>
  );
}

      