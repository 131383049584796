import axios from 'axios'

// API 
export default axios.create({
    baseURL : 'http://139.5.190.116'
    // baseURL: 'https://protectivedns.in'
    // baseURL: 'https://shreshtaitdev.in'

});

