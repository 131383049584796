import { Link, useLocation} from 'react-router-dom'
import { useState,useRef,useEffect } from 'react';


function IsActiveRoute(routeName){
   const location = useLocation();
   const currentPath = location.pathname;
   return currentPath.startsWith(routeName)
}


export default function Sidebar() {

  // DNSQuery Log Dropdown
  const [isDNSQLOpen, setDNSQLOpen] = useState(false);
  const DNSQLdropdownsRef = useRef(null);
  const toggleDNSQLDropdown = () => {
    setDNSQLOpen(!isDNSQLOpen);
  };

  //Highrisk Dropdown  
  const [isHighriskOpen, setHighriskOpen] = useState(false);
  const HighriskdropdownsRef = useRef(null);
  const toggleHighriskDropdown = () => {
    setHighriskOpen(!isHighriskOpen);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (DNSQLdropdownsRef.current && !DNSQLdropdownsRef.current.contains(event.target)) {
        // Clicked outside the dropdown or the toggle button, close it
        setDNSQLOpen(false);
      }
      if (HighriskdropdownsRef.current && !HighriskdropdownsRef.current.contains(event.target)) {
        // Clicked outside the dropdown or the toggle button, close it
        setHighriskOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDNSQLOpen]);
  
  return (
   // Side Bar
      <aside id="separator-sidebar" className="bg-white  w-[252px] h-full transition-transform -translate-x-full sm:translate-x-0">
         <div className="h-full overflow-y-auto border-r border-gray-300/80">
            <ul className="space-y-2 mt-3 font-medium">

               {/* Dashboard */}
               <li>
                  <Link to="/dashboard" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/dashboard')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                  <svg className={`transition duration-75 group-hover:text-primary-500 -ml-1 ${IsActiveRoute('/dashboard') && 'text-primary-500'}`} height="18px" width="30px" version="1.1" id="Capa_1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491.398 491.398">
                        <path d="M481.765,220.422L276.474,15.123c-16.967-16.918-44.557-16.942-61.559,0.023L9.626,220.422
                           c-12.835,12.833-12.835,33.65,0,46.483c12.843,12.842,33.646,12.842,46.487,0l27.828-27.832v214.872
                           c0,19.343,15.682,35.024,35.027,35.024h74.826v-97.62c0-7.584,6.146-13.741,13.743-13.741h76.352
                           c7.59,0,13.739,6.157,13.739,13.741v97.621h74.813c19.346,0,35.027-15.681,35.027-35.024V239.091l27.812,27.815
                           c6.425,6.421,14.833,9.63,23.243,9.63c8.408,0,16.819-3.209,23.242-9.63C494.609,254.072,494.609,233.256,481.765,220.422z"/>
                  </svg>
                     <span className={`group-hover:text-primary-500 ${IsActiveRoute('/dashboard') && 'text-primary-500'}`}>Overview</span>
                  </Link>
               </li>

               {/*DNS Anomalies*/}
               <li>
               <Link to="/anomalies" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/anomalies')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
               <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/anomalies') && 'text-primary-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 13.75C20 13.3358 19.6642 13 19.25 13H16.25C15.8358 13 15.5 13.3358 15.5 13.75V20.5H14V4.25C14 3.52169 13.9984 3.05091 13.9518 2.70403C13.908 2.37872 13.8374 2.27676 13.7803 2.21967C13.7232 2.16258 13.6213 2.09197 13.296 2.04823C12.9491 2.00159 12.4783 2 11.75 2C11.0217 2 10.5509 2.00159 10.204 2.04823C9.87872 2.09197 9.77676 2.16258 9.71967 2.21967C9.66258 2.27676 9.59196 2.37872 9.54823 2.70403C9.50159 3.05091 9.5 3.52169 9.5 4.25V20.5H8V8.75C8 8.33579 7.66421 8 7.25 8H4.25C3.83579 8 3.5 8.33579 3.5 8.75V20.5H2H1.75C1.33579 20.5 1 20.8358 1 21.25C1 21.6642 1.33579 22 1.75 22H21.75C22.1642 22 22.5 21.6642 22.5 21.25C22.5 20.8358 22.1642 20.5 21.75 20.5H21.5H20V13.75Z" fill="currentColor"/>
                  </svg>
                     <span className={`group-hover:text-primary-500 pl-[6px] ${IsActiveRoute('/anomalies') && 'text-primary-500'}`}> DNS Anomalies</span>
                  </Link>
               </li>

               {/* DNS Insights*/}
               <li>
               <Link to="/dnsinsights" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/dnsinsights')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                      <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/dnsinsights') && 'text-primary-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                     <path d="m17.351 3.063-8-3a1.009 1.009 0 0 0-.7 0l-8 3A1 1 0 0 0 0 4a19.394 19.394 0 0 0 8.47 15.848 1 1 0 0 0 1.06 0A19.394 19.394 0 0 0 18 4a1 1 0 0 0-.649-.937Zm-3.644 4.644-5 5A1 1 0 0 1 8 13c-.033 0-.065 0-.1-.005a1 1 0 0 1-.733-.44l-2-3a1 1 0 0 1 1.664-1.11l1.323 1.986 4.138-4.138a1 1 0 0 1 1.414 1.414h.001Z"/>
                  </svg>
                     <span className={`group-hover:text-primary-500 pl-[6px] ${IsActiveRoute('/dnsinsights') && 'text-primary-500'}`}> DNS Insights</span>
                  </Link>
               </li>

                {/* DNS Query Logs */}
                <li ref={DNSQLdropdownsRef}>
                    <div onClick={toggleDNSQLDropdown} className={`flex space-x-3 items-end justify-between py-3 px-6 pr-2 text-gray-700/80 cursor-pointer ${isDNSQLOpen ? 'border-r-[3.5px] border-primary-600 bg-primary-200/20' : 'group hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20'}`}>
                            <div className='flex items-center justify-end  space-x-3'>
                                <svg className={`w-6 h-6 transition duration-75 ${isDNSQLOpen ? 'text-primary-500' : 'group-hover:text-primary-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                                </svg>
                                <span className={`group-hover:text-primary-500 pl-[2px]  ${isDNSQLOpen && 'text-primary-500'}`}>DNS Query Logs</span>
                            </div>
                            <div className='flex items-end justify-end pr-4  space-x-3'>
                                <svg className={`w-5 h-5 transition duration-75 transform ${isDNSQLOpen ? 'rotate-90 text-primary-500' : 'group-hover:text-primary-500'}`}xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m9 5 7 7-7 7" />
                                </svg>
                            </div>
                        </div>

                    {isDNSQLOpen && (
                        <div className="mt-2">
                        {/* Realtime */}
                        <Link to="/dns-querylog/realtime" className={`group mr-2 flex space-x-3 items-center justify-end py-3 pr-8 text-gray-700/80 cursor-pointer group ${IsActiveRoute('/dns-querylog/realtime') && 'text-primary-500'}`}>
                        <svg className="w-5 h-5 transition duration-75 group-hover:text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"><path d="M883.712 749.568c0-13.312-9.216-22.528-22.528-22.528h-67.072v-98.816c0-13.312-9.216-22.528-22.528-22.528-13.312 0-22.528 9.216-22.528 22.528v143.36h116.736c8.704 0.512 17.92-8.704 17.92-22.016zM188.928 937.472h349.696c13.312 0 23.552-15.872 23.552-29.184s-9.728-29.696-23.552-29.696H203.776c-31.232 0-58.368-12.8-58.368-44.032V189.44c0-31.232 26.112-44.032 57.344-44.032h486.912c31.232 0 44.544 13.312 44.544 44.544v242.176c0 13.312 16.384 21.504 29.696 21.504 13.312 0 29.696-8.192 29.696-21.504V189.44c0-58.368-45.056-102.912-102.912-102.912h-501.76c-58.368 0-102.912 45.056-102.912 102.912v645.12c0 58.368 44.544 102.912 102.912 102.912z"  /><path d="M771.584 937.472c116.736 0 210.432-94.208 210.432-210.432s-94.208-210.432-210.432-210.432-210.432 94.208-210.432 210.432 93.696 210.432 210.432 210.432z m0-376.32c89.6 0 165.888 71.68 165.888 165.888 0 89.6-71.68 165.888-165.888 165.888-89.6 0-165.888-71.68-165.888-165.888 0-89.6 76.288-165.888 165.888-165.888zM229.376 301.568H650.24c13.312 0 22.528-9.216 22.528-22.528 0-13.312-9.216-22.528-22.528-22.528H229.376c-13.312 0-22.528 9.216-22.528 22.528s8.704 22.528 22.528 22.528z m165.888 420.864H229.376c-13.312 0-22.528 9.216-22.528 22.528s9.216 22.528 22.528 22.528h165.888c13.312 0 22.528-9.216 22.528-22.528s-9.216-22.528-22.528-22.528zM471.04 565.76H229.376c-13.312 0-22.528 9.216-22.528 22.528 0 13.312 9.216 22.528 22.528 22.528H471.04c13.312 0 22.528-9.216 22.528-22.528 0-13.312-8.704-22.528-22.528-22.528z m179.2-152.576H229.376c-13.312 0-22.528 9.216-22.528 22.528 0 13.312 9.216 22.528 22.528 22.528H650.24c13.312 0 22.528-9.216 22.528-22.528 0-13.312-8.704-22.528-22.528-22.528z"  /></svg>
                            <span className={`group-hover:text-primary-500 ${IsActiveRoute('/dns-querylog/realtime') && 'text-primary-500'}`}>Realtime Data</span>
                        </Link>
                        {/* Historical */}
                        <Link to="/dns-querylog/historical" className={`flex space-x-3 items-center justify-end py-3 pr-8 text-gray-700/80 cursor-pointer group ${IsActiveRoute('/dns-querylog/historical') && 'text-primary-500'}`}>
                            <svg className="w-5 h-5 transition duration-75 group-hover:text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 122.88 120.94">
                                <path d="M62.41,8.29a4.15,4.15,0,1,1,0-8.29A60.52,60.52,0,1,1,10.75,29.05L5.62,31a4.14,4.14,0,1,1-2.95-7.74L16,18.18a4.31,4.31,0,0,1,1.31-.27A4.13,4.13,0,0,1,22,20.78L26.14,34.4a4.13,4.13,0,0,1-7.91,2.4L17.39,34a52.22,52.22,0,1,0,45-25.75Zm0,28.68c9.92,0,18,3.77,18,8.41s-8,8.41-18,8.41-18-3.76-18-8.41,8-8.41,18-8.41Zm-18,31.75v6.46c3.19,11.36,34.78,9.16,35.94-.58V68.15C78.8,78.84,47,79.61,44.44,68.72Zm-.09-19.21v6.3c3.2,11.09,34.95,9.59,36.12.08v-6.3C78.89,60,46.87,60.14,44.35,49.51Zm0,9.35v6.46c3.2,11.35,34.95,9.81,36.12.07V58.94c-1.58,10.7-33.6,10.81-36.12-.08Zm0-34.15A3.59,3.59,0,0,1,51,22l2.69,6.54A3.6,3.6,0,1,1,47,31.26l-2.69-6.55ZM22.89,58a3.6,3.6,0,0,1,1-7.14l7,.94a3.6,3.6,0,1,1-1,7.14l-7-.94ZM41,93.16a3.6,3.6,0,0,1-5.7-4.4l4.32-5.6a3.6,3.6,0,1,1,5.7,4.4L41,93.16ZM80.48,95.1a3.59,3.59,0,1,1-6.65,2.73l-2.69-6.54a3.6,3.6,0,0,1,6.66-2.73l2.68,6.54Zm21.44-33.26A3.6,3.6,0,0,1,101,69L94,68a3.6,3.6,0,0,1,1-7.14l7,.94ZM83.85,26.65a3.6,3.6,0,1,1,5.69,4.4l-4.32,5.6a3.6,3.6,0,1,1-5.69-4.4l4.32-5.6Z"/>
                            </svg>
                            <span className={`group-hover:text-primary-500 ${IsActiveRoute('/dns-querylog/historical') && 'text-primary-500'}`}>Historical Data</span>
                        </Link>
                        </div>
                        )}
                </li>

                {/* Highrisk */}
                <li ref={HighriskdropdownsRef}>
                    <div onClick={toggleHighriskDropdown} className={`flex items-center justify-between py-3 px-6 text-gray-700/80 cursor-pointer ${isHighriskOpen ? 'border-r-[3.5px] border-primary-600 bg-primary-200/20' : 'group hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20'}`}>
                            <div className='flex items-center space-x-3.5'>
                                <svg className={`w-6 h-6 transition duration-75 ${isHighriskOpen ? 'text-primary-500' : 'group-hover:text-primary-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill="currentcolour" d="M8.6 3.2a1 1 0 0 0-1.6 1 3.5 3.5 0 0 1-.8 3.6c-.6.8-4 5.6-1 10.7A7.7 7.7 0 0 0 12 22a8 8 0 0 0 7-3.8 7.8 7.8 0 0 0 .6-6.5 8.7 8.7 0 0 0-2.6-4 1 1 0 0 0-1.6.7 10 10 0 0 1-.8 3.4 9.9 9.9 0 0 0-2.2-5.5A14.4 14.4 0 0 0 9 3.5l-.4-.3Z"/>
                                </svg>
                                <span className={`group-hover:text-primary-500 ${isHighriskOpen && 'text-primary-500'}`}>HighRisk TLD</span>
                            </div>
                            <div className='flex items-end justify-end  space-x-3'>
                                <svg className={`w-5 h-5 transition duration-75 transform ${isHighriskOpen ? 'rotate-90 text-primary-500' : 'group-hover:text-primary-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill="currentcolour" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m9 5 7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                    {isHighriskOpen && (
                        <div className="mt-2">
                            {/* Selective */}
                            <Link to="/highrisk/tldlist" className={`mr-2 flex space-x-3 items-center justify-end py-3 pr-[70px] text-gray-700/80 cursor-pointer group ${IsActiveRoute('/highrisk/tldlist') && 'text-primary-500'}`}>                 
                            <svg className="w-5 h-5 transition duration-75 group-hover:text-primary-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"/><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm8.933 3.519-1.726-1.726-1.414 1.414 3.274 3.274 5.702-6.84-1.538-1.282z"/></svg>
                                <span className={`group-hover:text-primary-500  ${IsActiveRoute('/highrisk/tldlist') && 'text-primary-500'}`}>TLD List</span>
                            </Link>
                            {/* Highriskdashboard */}
                            <Link to="/highrisk/anomalies" className={`flex space-x-3 items-center justify-end py-3 pr-[70px] text-gray-700/80 cursor-pointer group ${IsActiveRoute('/highrisk/anomalies') && 'text-primary-500'}`}>
                                <svg className="w-5 h-5 transition duration-75 group-hover:text-primary-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M14.2544 1.36453C13.1584 1.05859 12.132 1.38932 11.4026 2.05955C10.6845 2.71939 10.25 3.70552 10.25 4.76063V11.4551C10.25 12.7226 11.2775 13.75 12.5449 13.75H19.2394C20.2945 13.75 21.2806 13.3156 21.9405 12.5974C22.6107 11.868 22.9414 10.8416 22.6355 9.74563C21.5034 5.69003 18.31 2.49663 14.2544 1.36453ZM11.75 4.76063C11.75 4.10931 12.0201 3.52918 12.4175 3.16407C12.8035 2.80935 13.3035 2.65643 13.8511 2.8093C17.4013 3.80031 20.1997 6.59875 21.1907 10.1489C21.3436 10.6965 21.1907 11.1965 20.8359 11.5825C20.4708 11.9799 19.8907 12.25 19.2394 12.25H12.5449C12.1059 12.25 11.75 11.8941 11.75 11.4551V4.76063Z"/>
                                    <path fill="currentColor" d="M8.67232 4.71555C9.0675 4.59143 9.28724 4.17045 9.16312 3.77527C9.039 3.38009 8.61803 3.16036 8.22285 3.28447C4.18231 4.55353 1.25 8.32793 1.25 12.7892C1.25 18.2904 5.70962 22.75 11.2108 22.75C15.6721 22.75 19.4465 19.8177 20.7155 15.7772C20.8397 15.382 20.6199 14.961 20.2247 14.8369C19.8296 14.7128 19.4086 14.9325 19.2845 15.3277C18.2061 18.761 14.9982 21.25 11.2108 21.25C6.53805 21.25 2.75 17.462 2.75 12.7892C2.75 9.00185 5.23899 5.79389 8.67232 4.71555Z"/>
                                </svg>                            
                                <span className={`group-hover:text-primary-500 ${IsActiveRoute('/highrisk/anomalies') && 'text-primary-500'}`}>Analytics</span>
                            </Link>
                        </div>
                    )}
                </li>


               {/* Black/white Listing */}
               {/* <li>
                    <Link to="/white-blacklisting" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/white-blacklisting')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                    <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/white-blacklisting') && 'text-primary-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path fill="currentcolour" fill-rule="evenodd" d="M5.781 4.414a7 7 0 019.62 10.039l-9.62-10.04zm-1.408 1.42a7 7 0 009.549 9.964L4.373 5.836zM10 1a9 9 0 100 18 9 9 0 000-18z"/> 
                        </svg>
                            <span className={`group-hover:text-primary-500 pl-[6px] ${IsActiveRoute('/white-blacklisting') && 'text-primary-500'}`}>Allowlist/Blocklist</span>
                    </Link>
               </li> */}

               {/* Threat Intelligence */}
               <li>
                    <Link to="/threatintelligence" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/threatintelligence')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                        <svg className={`w-[22px] h-[22px] transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/threatintelligence') && 'text-primary-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M5 4a2 2 0 0 0-2 2v1h11l-2-2.3a2 2 0 0 0-1.5-.7H5ZM3 19V9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm11.7-7.7a1 1 0 0 0-1.4 1.4l.3.3H8a1 1 0 1 0 0 2h5.6l-.3.3a1 1 0 0 0 1.4 1.4l2-2c.4-.4.4-1 0-1.4l-2-2Z" clipRule="evenodd"/>
                        </svg>    
        
                            <span className={`group-hover:text-primary-500 pl-[3px] ${IsActiveRoute('/threatintelligence') && 'text-primary-500'}`}>Threat Intelligence</span>
                    </Link>
               </li>

                {/* Check Reputation */}
                {/* <li>
                        <Link to="/checkreputation" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/checkreputation')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                                <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/checkreputation') && 'text-primary-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                    <path d="M8 5.625c4.418 0 8-1.063 8-2.375S12.418.875 8 .875 0 1.938 0 3.25s3.582 2.375 8 2.375Zm0 13.5c4.963 0 8-1.538 8-2.375v-4.019c-.052.029-.112.054-.165.082a8.08 8.08 0 0 1-.745.353c-.193.081-.394.158-.6.231l-.189.067c-2.04.628-4.165.936-6.3.911a20.601 20.601 0 0 1-6.3-.911l-.189-.067a10.719 10.719 0 0 1-.852-.34 8.08 8.08 0 0 1-.493-.244c-.053-.028-.113-.053-.165-.082v4.019C0 17.587 3.037 19.125 8 19.125Zm7.09-12.709c-.193.081-.394.158-.6.231l-.189.067a20.6 20.6 0 0 1-6.3.911 20.6 20.6 0 0 1-6.3-.911l-.189-.067a10.719 10.719 0 0 1-.852-.34 8.08 8.08 0 0 1-.493-.244C.112 6.035.052 6.01 0 5.981V10c0 .837 3.037 2.375 8 2.375s8-1.538 8-2.375V5.981c-.052.029-.112.054-.165.082a8.08 8.08 0 0 1-.745.353Z"/>
                                </svg>
                                <span className={`group-hover:text-primary-500 ${IsActiveRoute('/checkreputation') && 'text-primary-500'}`}>Check Reputation</span>
                        </Link>
                    </li> */}

               {/* Report */}
               <li>
               <Link to="/report" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/report')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                     <svg className={`w-6 h-6 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/report') && 'text-primary-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path d="M9 7V2.13a2.98 2.98 0 0 0-1.293.749L4.879 5.707A2.98 2.98 0 0 0 4.13 7H9Z"/>
                     <path d="M18.066 2H11v5a2 2 0 0 1-2 2H4v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 20 20V4a1.97 1.97 0 0 0-1.934-2ZM10 18a1 1 0 1 1-2 0v-2a1 1 0 1 1 2 0v2Zm3 0a1 1 0 0 1-2 0v-6a1 1 0 1 1 2 0v6Zm3 0a1 1 0 0 1-2 0v-4a1 1 0 1 1 2 0v4Z"/>
                  </svg>
                     <span className="ms-2 group-hover:text-primary-500 pl-[4px] ">Report</span>
                  </Link>
               </li> 
            </ul>

            <ul className="mt-2  space-y-2 font-medium  dark:border-gray-700">

            {/* Documentation */}
            {/* <li>
               <Link to="/docs" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/docs')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                     <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/docs') && 'text-primary-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                           <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                        </svg>
                        <span className={`group-hover:text-primary-500 pl-[6px]  ${IsActiveRoute('/docs') && 'text-primary-500'}`}>Documentation</span>
                     </Link>
            </li> */}

               {/* Notifications */}
               {/* <li>
               <Link to="/notifications" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/notifications')? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                     <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/notifications') && 'text-primary-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                        <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                     </svg>
                     <span className={`group-hover:text-primary-500 ${IsActiveRoute('/notifications') && 'text-primary-500'}`}>Notifications</span>
                  </Link>
               </li> */}

               {/* Settings */}
               <li>
               <Link to="/settings" className={`flex space-x-3 items-center py-3 px-6 text-gray-700/80 ${IsActiveRoute('/settings') ? "border-r-[3.5px] border-primary-600 bg-primary-200/20":"flex space-x-3 items-center py-3 px-6 text-gray-700/80 hover:border-r-[3.5px] hover:border-primary-600 hover:bg-primary-200/20 group"}`}>
                     <svg className={`w-5 h-5 transition duration-75 group-hover:text-primary-500 ${IsActiveRoute('/settings') && 'text-primary-500'} mr-2`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"/>
                     </svg>
                     <span className={`flex-1 group-hover:text-primary-500 ${IsActiveRoute('/settings') && 'text-primary-500'}`}>Settings</span>
                  </Link>
               </li>
               
            </ul>
         </div>
      </aside>
  )
}




