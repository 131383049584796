// table.jsx
import React from "react";

export default function Table({ mtable, currentPage, handlePageChange, pageSize, handlePageSizeChange, totalPages, hasTableData,pageSizeOptions, }) {
  return (
    <section className="w-full bg-white rounded-md pb-3 overflow-hidden shadow-default">
      <div className="">
        {hasTableData ? (
          <>
            <div className="overflow-x-auto sm:overflow-hidden">
              <table className="w-full text-sm sm:text-base text-center border-b border-gray-300 rtl:text-center text-gray-800 dark:text-gray-400 bg-white dark:bg-gray-700">
                <thead className="text-lg text-slate-50 capitalize bg-primary-500  font-semibold">
                  <tr>
                    {/* Table Head */}
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">TimeStamp</th>
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">Source IP Address</th>
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">Malicious Domain Name</th>
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">Threat Intelligence Category</th>
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">Threat Score</th>
                    <th scope="col" className="px-4 sm:px-6 py-3 sm:py-4">DNS Record Type</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Table body, Data */}
                  {mtable.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.timestamp}</td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.source_ip}</td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.domain}</td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.category}</td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.score}</td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">{item.record}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination-container mt-4 m-2 flex flex-col sm:flex-row justify-between items-center">
            <div className="pagination-buttons flex items-center">
                  {/* left pagination */}
                    <button
                      onClick={() =>  handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={` px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                        currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                      }`}>
                      <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
                      </svg>
                    </button>
                    <span className="mx-2 text-gray-800">{`Page ${currentPage} of ${totalPages}`}</span>
                    {/* Right Pagination */}
                    <button
                      className={`px-2 rounded border border-gray-200 bg-transparent py-2 hover:scale-110 translate- duration-150 ${
                        currentPage === totalPages
                          ? "text-white cursor-not-allowed"
                          : "text-white cursor-pointer "
                      }`}
                      onClick={() =>  handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}>
                      <svg className="w-3 h-3 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
                      </svg>
                    </button>
                </div>
              {/* show Entries Per Page */}
              <div className="page-size-container mb-4 sm:mb-0 hover:scale-105 translate- duration-150">
                <span className="font-semibold text-gray-800">Show:</span>
                <select
                  value={pageSize}
                  id="number2"
                  onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                  className="p-1 rounded bg-white shadow-default outline-none mx-2 "
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span className="text-gray-800 font-semibold">entries</span>
              </div>
            </div>
          </>
        ) : (
          <p className="text-gray-800 text-center py-4">
            No data
          </p>
        )}
      </div>
    </section>
  );
}
