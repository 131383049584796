import React, { useState, useEffect } from 'react';
import Logo from "../../Assets/protectivedns.png";
import { Link, useNavigate } from 'react-router-dom';
import axios from '../API/axios';
import Logout from '../Auth/Logout/logout';

export default function Navbar({ toggleSidebar }) {
  const [isProfile, setIsProfile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [islogout, setIsLogout] = useState(false);
  const [iscontactusHovered, setIsContactUsHovered] = useState(false);
  const [isnotificationHovered, setIsNotificationHovered] = useState(false);
  const navigate = useNavigate();


  const handlelogout = () => {
    setIsLogout(!islogout);
  }

  useEffect(()=>{
      axios.get("/api/v1/profile",{withCredentials: true})
      .then(function(response){
          setUsername(response.data.username);
          setEmail(response.data.email);
      }).catch(function(error){
        if (error.response){
          if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          }
        }
      })
  },[navigate])

  const handleProfileDropDown = () => {
    setIsProfile(!isProfile);
  };

  const handleClickOutside = (event) => {
    if (
      isProfile &&
      event.target.closest('#dropdown') === null &  event.target.closest('.relative') === null) 
      {
      // Close the profile dropdown
      setIsProfile(false);
      }

    if (
      isDropdownOpen && event.target.closest('.relative') === null)
       {
      // Close the profile dropdown
      setIsDropdownOpen(false);
      }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  },);




  return (
    <header className="antialiased">
      <nav className="bg-white w-full border-b border-gray-200 px-4 lg:px-6 py-3">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            <button onClick={toggleSidebar}  className="p-[6px] mr-4 rounded cursor-pointer lg:inline text-gray-900 bg-gray-100">
              <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none"viewBox="0 0 16 12">
                <path stroke="currentColor"strokeLinecap="round"strokeLinejoin="round" strokeWidth="2"d="M1 1h14M1 6h14M1 11h7"/>
              </svg>
            </button>
            <Link to="/dashboard" className="flex items-center text-2xl font-semibold text-gray-900">
              <img className="w-7 h-7 mr-2" src={Logo} alt="logo" />
              DNS Shield
            </Link>
          </div>
          <div className="flex items-center lg:order-2 space-x-5">
            
            {/* Bell Icon */}
            {isnotificationHovered && (
                <div role="tooltip" className="absolute z-10 inline-block top-12 px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded-md shadow-sm opacity-100 tooltip">
                   Notification
                      <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            )}
            <Link to='/notification' type="button" onMouseEnter={() => setIsNotificationHovered(true)}onMouseLeave={() => setIsNotificationHovered(false)} data-dropdown-toggle="notification-dropdown" className="rounded-lg text-gray-900 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
              <span className="sr-only">View notifications</span>
                <svg className="w-6 h-6 relative" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                  <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                </svg>
            </Link>
            
            {/* Contact Icon  */}
            {iscontactusHovered && (
                <div role="tooltip" className="absolute z-10 inline-block top-12 px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded-md shadow-sm opacity-100 tooltip dark:bg-gray-700">
                   Contact Us
                      <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            )}
            <Link  to='/contact' target="_blank" onMouseEnter={() => setIsContactUsHovered(true)} onMouseLeave={() => setIsContactUsHovered(false)}>
              <svg className="w-6 h-6 text-gray-700"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
            </Link>

             {/* Nofification Dropdown*/}
                    {/* <div class=" overflow- z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg" id="notification-dropdown">
                        <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
                            Notifications
                        </div>
                        <div>
                        <Link to='#' class="flex py-3 px-4 border-b hover:bg-gray-100">
                            <div class="flex-shrink-0">
                            <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="Bonnie Green avatar"/>
                            <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 rounded-full border border-white bg-primary-700">
                                <svg class="w-2 h-2 text-white" aria="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18"><path d="M15.977.783A1 1 0 0 0 15 0H3a1 1 0 0 0-.977.783L.2 9h4.239a2.99 2.99 0 0 1 2.742 1.8 1.977 1.977 0 0 0 3.638 0A2.99 2.99 0 0 1 13.561 9H17.8L15.977.783ZM6 2h6a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm7 5H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Z"/><path d="M1 18h16a1 1 0 0 0 1-1v-6h-4.439a.99.99 0 0 0-.908.6 3.978 3.978 0 0 1-7.306 0 .99.99 0 0 0-.908-.6H0v6a1 1 0 0 0 1 1Z"/></svg>
                            </div>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5">New message from <span class="font-semibold text-gray-900 dark:text-white">Bonnie Green</span>: "Hey, what's up? All set for the presentation?"</div>
                                <div class="text-xs font-medium text-primary-700 dark:text-primary-400">a few moments ago</div>
                            </div>
                        </Link>
                        <Link to='#' class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">
                            <div class="flex-shrink-0">
                            <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="Jese Leos avatar"/>
                            <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-gray-900 rounded-full border border-white dark:border-gray-700">
                                <svg class="w-2 h-2 text-white" aria="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z"/></svg>
                            </div>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"><span class="font-semibold text-gray-900 dark:text-white">Jese leos</span> and <span class="font-medium text-gray-900 dark:text-white">5 others</span> started following you.</div>
                                <div class="text-xs font-medium text-primary-700 dark:text-primary-400">10 minutes ago</div>
                            </div>
                        </Link>
                        <Link to='#' class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">
                            <div class="flex-shrink-0">
                            <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png" alt="Joseph McFall avatar"/>
                            <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-red-600 rounded-full border border-white dark:border-gray-700">
                            <svg class="w-2 h-2 text-white" aria="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"> <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z"/> </svg>                      
                            </div>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"><span class="font-semibold text-gray-900 dark:text-white">Joseph Mcfall</span> and <span class="font-medium text-gray-900 dark:text-white">141 others</span> love your story. See it and view more stories.</div>
                                <div class="text-xs font-medium text-primary-700 dark:text-primary-400">44 minutes ago</div>
                            </div>
                        </Link>
                        <Link to='#' class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">
                            <div class="flex-shrink-0">
                            <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png" alt=""/>
                            <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-green-400 rounded-full border border-white dark:border-gray-700">
                                <svg class="w-2 h-2 text-white" aria="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M18 0H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2v4a1 1 0 0 0 1.707.707L10.414 13H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2ZM5 4h5a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm2 5H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm9 0h-6a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z"/></svg>
                            </div>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"><span class="font-semibold text-gray-900 dark:text-white">Leslie Livingston</span> mentioned you in a comment: <span class="font-medium text-primary-700 dark:text-primary-500">@bonnie.green</span> what do you say?</div>
                                <div class="text-xs font-medium text-primary-700 dark:text-primary-400">1 hour ago</div>
                            </div>
                        </Link>
                        <Link to='#' class="flex py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
                            <div class="flex-shrink-0">
                            <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/robert-brown.png" alt=""/>
                            <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-purple-500 rounded-full border border-white dark:border-gray-700">
                                <svg class="w-2 h-2 text-white" aria="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14"><path d="M11 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm8.585 1.189a.994.994 0 0 0-.9-.138l-2.965.983a1 1 0 0 0-.685.949v8a1 1 0 0 0 .675.946l2.965 1.02a1.013 1.013 0 0 0 1.032-.242A1 1 0 0 0 20 12V2a1 1 0 0 0-.415-.811Z"/></svg>
                            </div>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"><span class="font-semibold text-gray-900 dark:text-white">Robert Brown</span> posted a new video: Glassmorphism - learn how to implement the new design trend.</div>
                                <div class="text-xs font-medium text-primary-700 dark:text-primary-400">3 hours ago</div>
                            </div>
                        </Link>
                        </div>
                        <Link to='#' class="block py-2 text-base font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:text-white dark:hover:underline">
                            <div class="inline-flex items-center ">
                            <svg aria="true" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                            View all
                            </div>
                        </Link>
                    </div> */}

            {/* Profile */}
            <button type="button" onClick={handleProfileDropDown}className="inline-flex text-sm relative bg-gray-800 rounded-full ring-4 ring-gray-300" id="user-menu-button"aria-expanded="false" data-dropdown-toggle="dropdown">
               <span className="w-7 h-7 rounded-full text-white inline-flex items-center justify-center font-semibold text-xl uppercase">{username[0]}</span>
            </button>

            {/* Profile Dropdown */}
             {isProfile && (
                    <div className="absolute z-10 my-4 w-56 top-10 right-1.5 overflow-hidden text-base list-none bg-white rounded divide-y divide-gray-100 shadow-default dark:bg-gray-700 dark:divide-gray-600" id="dropdown">
                        <div className="py-3 px-4">
                            <span className="block text-sm font-semibold text-gray-900 dark:text-white">{username}</span>
                            <span className="block text-sm text-gray-500 truncate dark:text-gray-400">{email}</span>
                        </div>
                        <ul className="py-2 text-gray-500 dark:text-gray-400">
                            <li>
                                <Link to='/settings?tab=profile' onClick={handleProfileDropDown}  className="flex items-center py-2 px-4 text-sm  text-gray-600/80 hover:bg-gray-100/80 dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 18">
                                        <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                                    </svg>
                                    My Profile
                                </Link>
                            </li>
                            <li>
                                <Link to='/settings?tab=security' onClick={handleProfileDropDown} className="flex items-center py-2 px-4 text-sm  text-gray-600/80 hover:bg-gray-100/80 dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"/>
                                </svg>
                                  Two-Factor
                                </Link>
                            </li>
                            <li>
                                <Link to='/settings?tab=password' onClick={handleProfileDropDown}  className="flex items-center py-2 px-4 text-sm  text-gray-600/80 hover:bg-gray-100/80 dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                    <path d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z"/>
                                </svg>
                                   Change Password
                                </Link>
                            </li>
                        </ul>
                        <ul className=" text-gray-600/80" >
                            <li>
                                <div onClick={handlelogout} className="flex cursor-pointer items-center py-2.5 px-4 text-sm hover:bg-gray-100/80 dark:hover:bg-gray-400 dark:hover:text-white">
                                    <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/></svg>
                                    Sign out
                                </div>
                            </li>
                        </ul>
                    </div>)}
                    </div>
        </div>
      </nav>
      {islogout &&(
        <Logout actionPopup={handlelogout}/>
      )}
    </header>
  );
}
