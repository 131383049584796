import React from 'react'
import { AppTitle } from '../../Common/Helmet/helmet'

export default function Notification() {
  AppTitle("Notification | DNS Shield")
  return (
    <div className='font-bold bg-white h-16 flex justify-center items-center rounded-md shadow-default m-2'>
      <span className='text-lg text-slate-300 font-medium'>Notification feature to be added shortly</span>
    </div>
  )
}
