import { useState } from 'react';
import axios from '../../../../API/axios';
import Loading from '../../../../Common/Loading/loading';
import ErrorToast from '../../../../Common/Toast/errortoast';
import { useNavigate } from 'react-router-dom';
import ServerError from '../../../../ServerError/serverdown';
import Lottie from 'lottie-react';
import WarningAnimation from '../../../../../Assets/warning.json';


export default function Disable2fa({ actionClose,handleEnable }) {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [inputData, setInputData] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isTwoFAWarning, setisTwoFAWarning] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  // Toast Error
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);
    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };

// Handle OTP submit
const handleOtpSubmit = (e) => {
  e.preventDefault();
  if (isCheckboxChecked){
    if (inputData.length === 0) {
      showErrorToast("Please enter recovery key!");
    } else if (inputData.length !== 71 || !isNaN(inputData)) {
      showErrorToast("Please enter correct recovery key!");
    } else {
      Disable2faWithKey();
    } 
  } else {
    if (inputData.length === 0) {
        showErrorToast("Please enter otp!");
      } else if (inputData.length < 6 && !isNaN(inputData)) {
        showErrorToast("Please enter correct otp!");
      } else {
        Disable2faWithOTP();
      }
  }
};

  // Axios API for disabling 2FA
  const Disable2faWithKey = () => {
    axios.put('/api/v1/twofactor/disable',{
      recoverykey: inputData,
      otpcode: '',
      },{withCredentials: true}
      ).then(function (response) {
          if (response.data.message === 'recovery key is verified' && response.status === 200){ 
            setIsLoading(false);
            setisTwoFAWarning(true)
          }
        })
        .catch(function (error) {
          if (error.response){
            if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
              showErrorToast("Too many request try after 1 hour");
            } else if (error.response.data.message === 'invalid recovery key' && error.response.status === 400){
              showErrorToast("Invalid recovery key please try again");
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                  navigate("/")
              } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                navigate("/")
              } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                navigate("/")
              } else{
                setServerError(true);
              }
          } else{
            setServerError(true);
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  // Axios API for disabling 2FA
  const Disable2faWithOTP = () => {
    axios.put('/api/v1/twofactor/disable',{
      otpcode: inputData,
      recoverykey: ''
      },{withCredentials: true}
      ).then(function (response) {
          if (response.data.message === 'otp verified' && response.status === 200){ 
            setIsLoading(false);
            setisTwoFAWarning(true)
          }
        })
        .catch(function (error) {
          if (error.response){
            if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
              showErrorToast("Too many request try after 1 hour");
            } else if (error.response.data.message === 'invalid otp' && error.response.status === 400){
                  showErrorToast("Invalid OTP please try again");
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                  navigate("/")
              } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                navigate("/")
              } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                navigate("/")
              } else{
                setServerError(true);
              }
          } else{
            setServerError(true);
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };


  return (
    <>
    {serverError ?(
      <ServerError/>
      ):(
    <section className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {isLoading && <Loading />}
      <div className="fixed inset-0 bg-gray-300 bg-opacity-75 shadow-xl transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {isTwoFAWarning ? (
            <div className="relative transform overflow-hidden rounded-lg px-10 bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-max dark:bg-gray-800 dark:border-gray-700 dark:border">
                <div className="md:mx-auto">
                  <Lottie
                      animationData={WarningAnimation}
                      loop={true}
                      autoplay={true}
                      className='w-40 h-40 mx-auto'
                    />
                  <div className="text-center">
                      <h3 className="md:text-4xl text-2xl text-red-700 font-extrabold text-center">Warning !</h3>
                      <p className="text-red-600 my-3">Two-factor authentication adds an extra layer of security to your account.<br/> Disabling it may expose your account to increased risk.</p>
                      <div className='flex space-x-10 justify-center'>
                      <button onClick={()=>window.location.reload()} className="inline-flex w-full my-6 justify-center cursor-pointer rounded-md text-sm font-semibold py-2.5 px-12 shadow-sm bg-red-500 text-white sm:ml-3 sm:w-auto border border-red-500">Okay</button>
                    </div>
                  </div>
              </div>
          </div>
          ):(
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pt-5">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                    Disable 2FA
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to disable 2FA for your account? We strongly recommend keeping 2FA enabled to ensure the security of your account.
                    </p>
                  </div>
                  <div className='my-5 space-y-4'>
                    <div className='relative'>
                        <div className="inline-flex items-center justify-center absolute top-0 h-full w-10 text-gray-400">
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.0667 5C21.6586 5.95805 22 7.08604 22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.272 15.465 11.8598 15.465 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876C6.22633 20.6364 5.952 20.9096 5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C16.4995 2 17.282 2.15509 18 2.43738" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.8851 8.29353C17.8851 9.50601 16.8982 10.4889 15.6807 10.4889C14.4633 10.4889 13.4763 9.50601 13.4763 8.29353C13.4763 7.08105 14.4633 6.09814 15.6807 6.09814C16.8982 6.09814 17.8851 7.08105 17.8851 8.29353Z" stroke="#1C274C" strokeWidth="1.5"/>
                        </svg>
                        </div>
                        <input
                        onChange={(e) => setInputData(e.target.value)}
                        className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="************"
                        required=""
                        id="otp"
                        type="text"
                        autoComplete="off"
                        autoFocus
                        />
                    </div> 
                    <div className="flex items-start">
                        <div className={`flex items-center hover:ring-2 hover:p-[1px] hover:rounded-sm hover:ring-primary-500 ${isCheckboxChecked && 'p-[1px] ring-2 rounded-sm ring-primary-500'} `}>
                            {/* Check Box */}
                            <input
                            id="checkbox"
                            type="checkbox"
                            checked={isCheckboxChecked}
                            onClick={handleCheckboxChange}
                            aria-describedby="terms"
                            className="w-4 h-4 border cursor-pointer border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required=""

                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <p className="font-light text-gray-700">
                            Disable using recovery key
                            </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                onClick={handleOtpSubmit}>
                Proceed
              </button>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={actionClose}>
                Cancel
              </button>
            </div>
          </div>)}
        </div>
      </div>

      {/* Toast */}
      {isErrorToast && <ErrorToast toastMessage={toastMessage} />}
    </section>)}
    </>
  );
}


