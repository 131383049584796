import React, {useEffect, useState,useRef } from 'react';
import axios from '../../../components/API/axios';
import ServerError from "../../../components/ServerError/serverdown";
import { AppTitle } from '../../../components/Common/Helmet/helmet';
import Loading from '../../../components/Common/Loading/loading';
import DnsAnomaliesChart from '../../../components/SidebarMenus/DNSAnomalies/dnsAnomalieschart';
import { useNavigate } from 'react-router-dom';

export default function Anomalies() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Title
  AppTitle("DNS Anomaly | DNS Shield")

// Server Down-Error
const [serverError, setServerError] = useState(false);

// Common Header
const [optiontimestamp, setOptionTimestamp] = useState('Last 15 minutes');
const [optionright, setOptionRight] = useState('Last 15 minutes');
const [isDropdownOpenRight, setDropdownOpenRight] = useState(false);
const [isDropdownOpenTimestamp, setDropdownOpenTimestamp] = useState(false);
const [isErrorToast, setIsErrorToast] = useState(false)
const [toastMessage, setToastMessage] = useState('');
const [barData, setBarData] = useState([]);
const dropdownRefRight = useRef(null);

//Header Options 
const optionstimestamp = ['Last 15 minutes', 'Last 30 minutes', 'Last 1 hour', 'Last 3 hours', 'Last 8 hours', 'Last 12 hours', 'Last 24 hours', 'Last 1 week', 'Last 1 month','Last 3 months', 'Last 6 months']

// Determine which bar is the tallest
const determineAnomalies = () => {
  let max = 0;
  const seriesData = barData.map(item => {
    const value = parseInt(item[1]);
    if (value > max) max = value;
    return {
      x: new Date(item[0]).getTime(),
      y: value,
    };
  });

  return seriesData.map(data => ({
    ...data,
    fillColor: data.y === max ? '#FF0000' : '#007bff', // Red for the tallest bar, blue otherwise
  }));
};

   // Toast Error
   const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);
  };

// Header Left and Timestamp Dropdown Click
const handleOptionClickRight = () => {
  setDropdownOpenRight(!isDropdownOpenRight);
  setDropdownOpenTimestamp(false);
}

// handle timespamp
const handleOptionClickTimestamp = () => {
  setDropdownOpenTimestamp(!isDropdownOpenTimestamp);
}

// Header Timestamp Dropdown Option Click
const handleOptionTimestamp = (optiontimestamp) => {
  setOptionTimestamp(optiontimestamp);
  setDropdownOpenTimestamp(false);
};

// Header Dropdown Outside click
useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRefRight.current && !dropdownRefRight.current.contains(event.target)) {
      setDropdownOpenRight(false);
        setDropdownOpenTimestamp(false);
    }
  };

  // Header Evenet Listener
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [])


// close dropdown alternative
const handleOptionClickRightButton = () => {
  setOptionRight(optiontimestamp);
  setDropdownOpenRight(!isDropdownOpenRight);
}

    //Highrisk Stats Api
  const anomaly = () => {
      setIsLoading(true);
      const timerangeValues = {
        'Last 15 minutes': 15,
        'Last 30 minutes': 30,
        'Last 1 hour': 60,
        'Last 3 hours': 180,
        'Last 8 hours': 480,
        'Last 12 hours': 720,
        'Last 24 hours': 1440,
        'Last 1 week': 10080,
        'Last 1 month': 43200,
        'Last 3 months': 129600,
        'Last 6 months': 259200,
      };
    
      const timerangeValue = timerangeValues[optionright];
      setIsLoading(true);

      axios.get(`/api/v1/anomaly/data?timerange=${timerangeValue}`,
      {withCredentials: true})
      .then(function (response) {
        setServerError(false);
        const data = response.data;
        setBarData(data.chartdata);
        setIsLoading(false);
        setIsErrorToast(false);
      })
      .catch(function (error) {
        if (error.response){
          if (error.response.data.message && error.response.status === 400){
            showErrorToast(error.response.data.message)
          } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
          } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          } else{
            setServerError(true);
          }
        }else{
          setServerError(true);
        }
        setIsLoading(false);
      })
    }


  useEffect(()=>{
      anomaly();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionright]);  

  return (
<section className='w-full h-full space-y-4 p-2'>
    {isLoading && <Loading />}
    {serverError ? (
      <ServerError />
    ) : (
      <>
        {/* Toast */}
        {isErrorToast ? (
          <div className="flex items-center justify-center">
            <div className='rounded-md px-10 py-2 bg-red-100/90 w-max inline-flex'>
              <svg fill="#B91C1C" className='-mb-3' xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24">
                <path d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "></path>
              </svg>
              <p className="text-gray-950 font-medium">{toastMessage}</p>
            </div>
          </div>
        ) : (
          <DnsAnomaliesChart
          barData={barData}
          optionstimestamp={optionstimestamp}
          optionright={optionright}
          optiontimestamp={optiontimestamp}
          isDropdownOpenRight={isDropdownOpenRight}
          isDropdownOpenTimestamp={isDropdownOpenTimestamp}
          dropdownRefRight={dropdownRefRight}
          handleOptionClickRight={handleOptionClickRight}
          handleOptionClickTimestamp={handleOptionClickTimestamp}
          handleOptionClickRightButton={handleOptionClickRightButton}
          handleOptionTimestamp={handleOptionTimestamp}
          determineAnomalies={determineAnomalies}
         />
        )}
      </>
    )}
  </section>
  )
}
