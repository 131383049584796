import {useState, useEffect} from 'react'

export default function Toastwarning({message,handletoast}) {
  const [progress, setProgress] = useState(100);
  const toasttime = 4000;

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + toasttime; // 5 seconds

    const updateProgress = () => {
      const currentTime = Date.now();
      const remaining = endTime - currentTime;
      const newProgress = Math.max(0, (remaining / toasttime) * 100);

      setProgress(newProgress);

      if (remaining > 0) {
        setTimeout(updateProgress, 100);

      } else {
        handletoast();
      }
    };

    updateProgress();

    return () => {
      setProgress(100);
    };
  }, [handletoast]);

    return (
      <div className='bg-amber-50 border fixed overflow-hidden top-4 right-4 border-amber-50 w-[400px] h-20 shadow-xl rounded-md border-b-0'>
      <div className='flex'>
          <svg fill="#B45309" className='p-4 ml-3' xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 367.011 367.01">
          <g><g>
              <path d="M365.221,329.641L190.943,27.788c-1.542-2.674-4.395-4.318-7.479-4.318c-3.084,0-5.938,1.645-7.48,4.318L1.157,330.584
                  c-1.543,2.674-1.543,5.965,0,8.639c1.542,2.674,4.395,4.318,7.48,4.318h349.65c0.028,0,0.057,0,0.086,0
                  c4.77,0,8.638-3.863,8.638-8.639C367.011,332.92,366.342,331.1,365.221,329.641z M23.599,326.266L183.464,49.381l159.864,276.885 H23.599z"/>
              <path d="M174.826,136.801v123.893c0,4.773,3.867,8.638,8.638,8.638c4.77,0,8.637-3.863,8.637-8.638V136.801
                      c0-4.766-3.867-8.637-8.637-8.637C178.693,128.165,174.826,132.036,174.826,136.801z"/>
              <path d="M183.464,279.393c-5.922,0-10.725,4.8-10.725,10.722s4.803,10.729,10.725,10.729c5.921,0,10.725-4.809,10.725-10.729
                      C194.189,284.193,189.386,279.393,183.464,279.393z"/></g></g>
          </svg>
          <div className='mt-3 mx-5'>
              <h1 className='text-amber-700 font-extrabold text-lg'>Warning</h1>
              <p className='text-amber-700'>{message}</p>
          </div>
          <svg onClick={handletoast} className='w-4 h-4 ml-auto m-2 cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#B45309"/>
          </svg>
      </div>
      <div className="overflow-hidden mt-[2.5px] bg-amber-200 h-2 w-full">
              <span
                className="h-full bg-amber-500 border-l-amber-500  border-b-amber-500 w-1/2 block"
                style={{ width: `${progress}%`, transition: 'width 0.1s ease-in-out' }}
              ></span>
      </div>
  </div>
  )
}
