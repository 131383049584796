import React, { useEffect, useState } from 'react'
import axios from "../../../API/axios";
import Loading from '../../../Common/Loading/loading';
import ServerError from '../../../ServerError/serverdown';
import { useNavigate } from 'react-router-dom';


export default function Profile() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expiry, setExpiry] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const navigate = useNavigate();

    // Profile 
    useEffect(()=>{
        setIsLoading(true);
        axios.get("/api/v1/profile",
        {withCredentials: true})
        .then(function(response){
            setUsername(response.data.username);
            setEmail(response.data.email);
            setExpiry(response.data.expiry)
            setIsLoading(false);
        }).catch(function(error){
            if (error.response){
                if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                  navigate("/")
                } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                  navigate("/")
                } else{
                  setServerError(true);
                }
              } else{
                setServerError(true);
              }
              setIsLoading(false);
        })
    },[navigate])
    
  return (
    <>
    {isLoading && (<Loading/>)}
   {serverError ?(
     <ServerError/>
     ):(
       <>
    <div className='w-full h-full'>
        <div>
            <h1 className='text-gray-900 font-bold text-xl'>My Profile</h1>
            <p className='py-2'>Your protective dns profile.</p>
        </div>
        <hr className='border-gray-300/80 my-2'/>
        <div className='flex space-x-10 text-gray-600 font-semibold'>
            <img className="w-24 h-24 rounded-full shadow-xl border border-primary-600" src="https://img.freepik.com/premium-vector/man-profile-cartoon_18591-58482.jpg?w=740" alt='profile'/>
            <div className='mt-2'>
                    <div className='flex justify-start items-center space-x-12'>
                        <p>Username:</p><p>{username}</p>
                    </div>
                    <div className='flex justify-start items-center space-x-4 mt-2'>
                        <p>Email address:</p><p>{email}</p>
                    </div>
                    <div className='flex justify-start items-center space-x-9 mt-2'>
                        <p>User Expiry:</p><p>{expiry.replace(' 00:00:00 GMT','')}</p>
                    </div>
                   
             </div>
        </div>
    </div>
    </>)}
    </>
  )
}
