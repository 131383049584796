import { AppTitle } from '../Common/Helmet/helmet';
import logo from "../../Assets/contactus.svg"

export default function Contactus() {

  // Title//
  AppTitle("Contact Us | DNS Shield"); 

  return (
    <section className="w-full h-full p-8 pb-0 mx-auto">
        <div>
            <h1 className="mt-2 text-2xl font-bold text-gray-800 md:text-3xl">Contact us</h1>
            <p className="mt-3 text-gray-500 font-semibold">If you have any questions about DNS Shield, we would love to hear from you.</p>
        </div>

        <div className="grid gap-10 mt-10 grid-cols-2">
            <div className="grid grid-cols-2 gap-10">
                <div>
                    <span className="inline-block p-3 text-primary-500 rounded-full bg-primary-200/80">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                    </span>

                    <h2 className="mt-4 text-base font-semibold text-gray-800">Email</h2>
                    <p className="mt-2 font-semibold text-gray-500">Our friendly team is here to help.</p>
                    <p className="mt-2 text-primary-500 font-semibold">support@shreshtait.com</p>
                </div>

                <div>
                    <span className="inline-block p-3 text-primary-500 rounded-full bg-primary-200/80">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 3 6 2V1m5 2 1-1V1M9 7v11M9 7a5 5 0 0 1 5 5M9 7a5 5 0 0 0-5 5m5-5a4.959 4.959 0 0 1 2.973 1H12V6a3 3 0 0 0-6 0v2h.027A4.959 4.959 0 0 1 9 7Zm-5 5H1m3 0v2a5 5 0 0 0 10 0v-2m3 0h-3m-9.975 4H2a1 1 0 0 0-1 1v2m13-3h2.025a1 1 0 0 1 1 1v2M13 9h2.025a1 1 0 0 0 1-1V6m-11 3H3a1 1 0 0 1-1-1V6"/>
                    </svg>
                    </span>
                    <h2 className="mt-4 text-base font-semibold text-gray-800">Bug report</h2>
                    <p className="mt-2 text-gray-500 font-semibold">Our team is here to help you resolve any issues.</p>
                    <p className="mt-2 text-primary-500 font-semibold">soc@shreshtait.com</p>
                </div>

                <div>
                    <span className="inline-block p-3 text-primary-500 rounded-full bg-primary-200/80">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                    </span>
                    
                    <h2 className="mt-4 text-base font-semibold text-gray-800">Phone</h2>
                    <p className="mt-2 text-gray-500 font-semibold">Mon-Sat from 10am to 6pm.</p>
                    <p className="mt-2 text-primary-500 font-semibold">+91 8147090063</p>
                </div>
                <div>
                    <span className="inline-block p-3 text-primary-500 rounded-full bg-primary-200/80">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </span>
                    
                    <h2 className="mt-4 text-base font-semibold text-gray-800">Office</h2>
                    <p className="mt-2 text-gray-500 font-semibold">Shreshta IT Technologies Pvt. Ltd.</p>
                    <p className="mt-2 text-primary-500 font-semibold">Sateri Niwas, Rani Laxmibai Rd,
                    Hindu Nagar, Tilakwadi, Belagavi,
                    Karnataka 590006, India</p>
                </div>

            </div>
            <img src={logo} alt='' className='-mt-32'/>
        </div>
</section>
  )
}
