import Profile from '../Profile/profile';
import Password from '../ChangePassword/password';
import Email from '../ChangeEmail/email';
import Security from '../Security/security';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppTitle } from '../../../Common/Helmet/helmet';

export default function Settings() {

  // Title
  AppTitle("Settings | DNS Shield")
  
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('profile');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/settings?tab=${tab}`);
  };


  return (
    <section className='w-full h-full p-2'>
       <div className='w-full h-full px-6 py-2 bg-white rounded-md'>
            <h1 className='text-2xl font-bold mt-3.5 mb-5'>Settings</h1>
            <div className="text-center text-sm text-gray-600 font-semibold px-1">
              {/* Profile */}
                <ul className="flex flex-wrap -mb-px">
                <li className="me-2">
                    <div
                    onClick={() => handleTabClick('profile')}
                    className={`inline-block cursor-pointer p-4 pb-2 border-b-2 rounded-t-lg hover:text-primary-600 hover:border-primary-600 ${activeTab === 'profile' ? 'text-primary-600 border-primary-600' : 'border-transparent'}`}
                    >
                    Profile
                    </div>
                </li>
                {/* Password Change */}
                <li className="me-2">
                    <div
                    onClick={() => handleTabClick('password')}
                    className={`inline-block cursor-pointer p-4 pb-2 border-b-2 rounded-t-lg hover:text-primary-600 hover:border-primary-600 ${activeTab === 'password' ? 'text-primary-600 border-primary-600' : 'border-transparent'}`}
                    >
                    Password
                    </div>
                </li>
                {/* Email Change */}
                <li className="me-2">
                    <div
                    onClick={() => handleTabClick('email')}
                    className={`inline-block cursor-pointer p-4 pb-2 border-b-2 rounded-t-lg hover:text-primary-600 hover:border-primary-600 ${activeTab === 'email' ? 'text-primary-600 border-primary-600' : 'border-transparent'}`}
                    >
                    Email
                    </div>
                </li>
                {/* Security, Two factor */}
                <li className="me-2">
                    <div
                    onClick={() => handleTabClick('security')}
                    className={`inline-block cursor-pointer p-4 pb-2 border-b-2 rounded-t-lg hover:text-primary-600 hover:border-primary-600 ${activeTab === 'security' ? 'text-primary-600 border-primary-600' : 'border-transparent'}`}
                    >
                    Two-Factor
                    </div>
                </li>
                </ul>
            </div>
            <div className="mt-6">
                {activeTab === 'profile' && <Profile/>}
                {activeTab === 'password' && <Password/>}
                {activeTab === 'email' && <Email/>}
                {activeTab === 'security' && <Security/>}
            </div>
        </div>
    </section>
  );
}
