
//home.jsx
import React, { useState, useEffect,useRef } from 'react';
import Stats from './stats';
import Table from './Table';
import Areachart from './areachart';
import Piechart from './piechart';
import Header from '../../Common/Header/header';
import Table2 from './Table2';
import axios from "../../API/axios";
import ServerError from '../../ServerError/serverdown';
import { AppTitle } from '../../Common/Helmet/helmet';
import Loading from '../../Common/Loading/loading';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);
  const navigate = useNavigate();

  // Page Title
  AppTitle("Dashboard | DNS Shield");

// Server Down-Error
const [serverError, setServerError] = useState(false);

const incrementPendingRequests = () => {
  setPendingRequests(prevCount => prevCount + 1);
};

// Decrement Pending Requests
const decrementPendingRequests = () => {
  setPendingRequests(prevCount => prevCount - 1);
  if (pendingRequests <= 1) {
  }
};

useEffect(() => {
  if (pendingRequests >= 1) {
    setIsLoading(true);
  } else {
    setIsLoading(false);
  }
}, [pendingRequests]);

// Common Header
const [optionleft, setOptionLeft] = useState('Filter By');
const [optiontimestamp, setOptionTimestamp] = useState('Last 15 minutes');
const [optionrefresh, setOptionRefresh] = useState('Dont refresh');
const [optionright, setOptionRight] = useState('Last 15 minutes');
const [isDropdownOpenLeft, setDropdownOpenLeft] = useState(false);
const [isDropdownOpenRight, setDropdownOpenRight] = useState(false);
const [isDropdownOpenTimestamp, setDropdownOpenTimestamp] = useState(false);
const [isDropdownOpenRefresh, setDropdownOpenRefresh] = useState(false);
const [isErrorToast, setIsErrorToast] = useState(false);
const [toastMessage, setToastMessage] = useState('');
const dropdownRefLeft = useRef(null);
const dropdownRefRight = useRef(null);

//Header Options 
const optionsleft = ['Source IP','Domain Name','Record Type','Category Name',];
const optionstimestamp = ['Last 15 minutes', 'Last 30 minutes', 'Last 1 hour', 'Last 3 hours', 'Last 8 hours', 'Last 12 hours', 'Last 24 hours', 'Last 1 week', 'Last 1 month','Last 3 months', 'Last 6 months']
const optionsrefresh = ['Dont refresh','every 1 minutes', 'every 5 minutes', 'every 10 minutes', 'every 30 minutes', 'every 60 minutes']

 // Toast Error
 const showErrorToast = (message) => {
  setToastMessage(message);
  setIsErrorToast(true);

  setTimeout(() => {
    setIsErrorToast(false);
  }, 4000);
};

// searchbar data
const [searchquery, setSearchQuery] = useState('')
const [query, setQuery] = useState('');
const [searchPlaceholder, setSearchPlaceholder] = useState('Search');
const domainRegex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;
const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;

// hadle searchbar
const handleSearch = () => {
  if (optionleft === 'Filter By'){
    showErrorToast('Please choose the filter type')
  } else if (optionleft === 'Domain name' && searchquery.length === 0){
    showErrorToast('Please provide a domain name')
  } else if (optionleft === 'Domain Name' && !domainRegex.test(searchquery)){
    showErrorToast('Please provide a valid domain name')
  } else if (optionleft === 'Source IP' && searchquery.length === 0){
    showErrorToast('Please provide a source ip')
  }  else if (optionleft === 'Source IP' && !ipRegex.test(searchquery)){
    showErrorToast('Please provide a valid ip address')
  } else if (optionleft === 'Record Type' && searchquery.length === 0){
    showErrorToast('Please provide a record type')
  } else if (optionleft === 'Category Name' && searchquery.length === 0){
    showErrorToast('Please provide a Category name')
  } else {
    setQuery(searchquery); 
    dashboardstats();
    maliciousTable();
    TopdomainData();
    ToptalkerData(); 
  }
}

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    handleSearch(); // Trigger the search button click event
  }
};

// Header Left and Timestamp Dropdown Click
const handleOptionClickLeft = () => setDropdownOpenLeft(!isDropdownOpenLeft);
const handleOptionClickRight = () => {
  setDropdownOpenRight(!isDropdownOpenRight);
  setDropdownOpenTimestamp(false);
  setDropdownOpenRefresh(false);
}

// handle timespamp
const handleOptionClickTimestamp = () => {
  setDropdownOpenRefresh(false);
  setDropdownOpenTimestamp(!isDropdownOpenTimestamp);
}

// Handle autorefresh
const handleOptionClickRefresh = () => {
  setDropdownOpenTimestamp(false);
  setDropdownOpenRefresh(!isDropdownOpenRefresh);
}

// Header Left Dropdown Option Click
const handleOptionLeft = (optionleft) => {
  setOptionLeft(optionleft);
  setSearchPlaceholder(`Search by ${optionleft}`);
  setDropdownOpenLeft(false);
};

// Header Timestamp Dropdown Option Click
const handleOptionTimestamp = (optiontimestamp) => {
  setOptionTimestamp(optiontimestamp);
  setDropdownOpenTimestamp(false);
};

// Header Autorefresh Drowpdown option click
const handleOptionRefresh = (optionrefresh) => {
  setOptionRefresh(optionrefresh);
  setDropdownOpenRefresh(false);
};


// Header Dropdown Outside click
useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRefLeft.current && !dropdownRefLeft.current.contains(event.target)) {
      setDropdownOpenLeft(false);
    }
    if (dropdownRefRight.current && !dropdownRefRight.current.contains(event.target)) {
      setDropdownOpenRight(false);
        setDropdownOpenTimestamp(false);
        setDropdownOpenRefresh(false);
    }
  };

  // Header Evenet Listener
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [])


// close dropdown alternative
const handleOptionClickRightButton = () => {
  setOptionRight(optiontimestamp);
  setOptionRefresh(optionrefresh);
  setDropdownOpenRight(!isDropdownOpenRight);
}

  // Stats
  const [tcount, setTcount] = useState(0);
  const [mcount, setMcount] = useState(0);
  const [intel, setIntel] = useState(0);
  const [mode, setMode] = useState();
  const [categorydata, setCategoryData] = useState('');
  const [categorycount, setCategoryCount] = useState(0);
  const [areaData, setAreaData] = useState([]);
  const [mtable, setMtable] = useState([]);
  const [avg_query, setAvg_query] =useState('');

    // Stats Api
  const dashboardstats = () => {
    incrementPendingRequests();
      const timerangeValues = {
        'Last 15 minutes': 15,
        'Last 30 minutes': 30,
        'Last 1 hour': 60,
        'Last 3 hours': 180,
        'Last 8 hours': 480,
        'Last 12 hours': 720,
        'Last 24 hours': 1440,
        'Last 1 week': 10080,
        'Last 1 month': 43200,
        'Last 3 months': 129600,
        'Last 6 months': 259200,
      };
      const timerangeValue = timerangeValues[optionright];

      const searchtypeValue = {
        'Domain Name': 'domain',
        'Source IP': 'ip',
        'Category Name':'category',
        'Record Type':'record'
      }

      const searchtype = searchtypeValue[optionleft];

      axios.get(`/api/v1/home/stats?timerange=${timerangeValue}&type=${searchtype}&search=${query}`,
      {withCredentials: true})
      .then(function (response) {
        setServerError(false);
        const data = response.data;
        setTcount(data.total);
        setMcount(data.malicious);
        setMode(data.mode);
        setIntel(data.intel);
        setAvg_query(data.avg_query)
        const counts = data.category.map(item => item.count);
        const categories = data.category.map(item => item.category);
        setCategoryCount(counts);
        setCategoryData(categories);
        setAreaData(data.chartdata);
        setIsErrorToast(false);
      })
      .catch(function (error) {
        if (error.response){
          if (error.response.data.message && error.response.status === 400){
            showErrorToast(error.response.data.message)
          } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
          } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          } else{
            setServerError(true);
          }
        }else{
          setServerError(true);
        }
      })
      .finally(() => {
        decrementPendingRequests(); 
      });
  };


  useEffect(()=>{
     dashboardstats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[optionright, query]);

  // Table Data
  const pageSizeOptions = [5, 10];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const hasTableData = mtable.length > 0;

  //Malicious Table Api
const maliciousTable = () => {
  incrementPendingRequests();
    const timerangeValues = {
      'Last 15 minutes': 15,
      'Last 30 minutes': 30,
      'Last 1 hour': 60,
      'Last 3 hours': 180,
      'Last 8 hours': 480,
      'Last 12 hours': 720,
      'Last 24 hours': 1440,
      'Last 1 week': 10080,
      'Last 1 month': 43200,
      'Last 3 months': 129600,
      'Last 6 months': 259200,
    };
  
    const timerangeValue = timerangeValues[optionright];

    const searchtypeValue = {
      'Domain Name': 'domain',
      'Source IP': 'ip',
      'Category Name':'category',
      'Record Type':'record'
    }

    const searchtype = searchtypeValue[optionleft];

    axios.get(`/api/v1/home/malicious?page=${currentPage}&size=${pageSize}&timerange=${timerangeValue}&type=${searchtype}&search=${query}`
      ,{withCredentials: true}
      ).then((response) => {
        setServerError(false);
        setMtable(response.data.mdata);
        setIsErrorToast(false);
      })
      .catch((error) => {
        if (error.response){
          if (error.response.data.message && error.response.status === 400){
            showErrorToast(error.response.data.message)
          } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
          } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          } else{
            setServerError(true);
          }
        }else{
          setServerError(true);
        }
      })
      .finally(() => {
        decrementPendingRequests(); 
      });
  }

  // Malicous Table Handle
  useEffect(()=>{
    maliciousTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, optionright, query]);

  // Conditional rendering logic
  const shouldRenderTable = optionright === 'Last 15 minutes';

   // Table Page Change
   const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(10 / pageSize)) {
      setCurrentPage(newPage);
    }
  };

  // Table Page Show Page-Size 
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  // Table total page calculation
  const totalPages = Math.ceil(10 / pageSize);

  // Top Talker
  const [currentTTPage, setcurrentTTPage] = useState(1);
  const [ttData, setTTData] = useState([]);
  const hasTableTTData = ttData?.length > 0;

  // Top Talker Api
  const ToptalkerData = () => {
    incrementPendingRequests();
      const timerangeValues = {
        'Last 15 minutes': 15,
        'Last 30 minutes': 30,
        'Last 1 hour': 60,
        'Last 3 hours': 180,
        'Last 8 hours': 480,
        'Last 12 hours': 720,
        'Last 24 hours': 1440,
        'Last 1 week': 10080,
        'Last 1 month': 43200,
        'Last 3 months': 129600,
        'Last 6 months': 259200,
      };
      const timerangeValue = timerangeValues[optionright];

      const searchtypeValue = {
        'Domain Name': 'domain',
        'Source IP': 'ip',
        'Category Name':'category',
        'Record Type':'record'
      }

      const searchtype = searchtypeValue[optionleft];
  
      axios.get(`/api/v1/home/toptalkers?page=${currentTTPage}&size=${5}&timerange=${timerangeValue}&type=${searchtype}&search=${query}`
        ,{withCredentials: true})
        .then((response) => {
          setServerError(false);
          setTTData(response.data.toptalkers);
          setIsErrorToast(false);
        })
        .catch((error) => {
          if (error.response){
            if (error.response.data.message && error.response.status === 400){
              showErrorToast(error.response.data.message)
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
              navigate("/")
            } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
              navigate("/")
            } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
              navigate("/")
            } else{
              setServerError(true);
            }
          }else{
            setServerError(true);
          }    
        })
        .finally(() => {
          decrementPendingRequests(); 
        });
  }

  // Top Talker Handle
  useEffect(()=>{
    ToptalkerData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTTPage, optionright, query]);

     // Top Talker Page change
  const handleTTPageChange = (newTTPage) => {
    if (newTTPage >= 1 && newTTPage <= Math.ceil(10 / 5)) {
      setcurrentTTPage(newTTPage);
    }
  };
  const totalTTPages = Math.ceil(1 / 5);

  // Top Talker Page-jump
  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > totalTTPages) {
      settoast(true);
      setToastmessage("Invalid Page Number")
      return;
    }
    setcurrentTTPage(pageNumber);
  };

  // Top Domain
  const [currentTDPage, setcurrentTDPage] = useState(1);
  const [tdData, setTDData] = useState([]);
  const hasTableTDData = tdData?.length > 0;

  // Top Domain Api
  const TopdomainData = () => {
    incrementPendingRequests();
      const timerangeValues = {
        'Last 15 minutes': 15,
        'Last 30 minutes': 30,
        'Last 1 hour': 60,
        'Last 3 hours': 180,
        'Last 8 hours': 480,
        'Last 12 hours': 720,
        'Last 24 hours': 1440,
        'Last 1 week': 10080,
        'Last 1 month': 43200,
        'Last 3 months': 129600,
        'Last 6 months': 259200,
      };
    
      const timerangeValue = timerangeValues[optionright];

      const searchtypeValue = {
        'Domain Name': 'domain',
        'Source IP': 'ip',
        'Category Name':'category',
        'Record Type':'record'
      }

      const searchtype = searchtypeValue[optionleft];

      axios.get(`/api/v1/home/topdomains?page=${currentTDPage}&size=${5}&timerange=${timerangeValue}&type=${searchtype}&search=${query}`
        ,{withCredentials: true})
        .then((response) => {
          setServerError(false);
          setTDData(response.data.topdomains);
          setIsErrorToast(false);
        })
        .catch((error) => {
          if (error.response){
            if (error.response.data.message && error.response.status === 400){
              showErrorToast(error.response.data.message)
            } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
              navigate("/")
            } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
              navigate("/")
            } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
              navigate("/")
            } else{
              setServerError(true);
            }
          }else{
            setServerError(true);
          }
        })
        .finally(() => {
          decrementPendingRequests(); 
        });
  }

  // Top Domain handle
  useEffect(()=>{
    TopdomainData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTDPage, optionright, query])

  // Top domain Page change
  const handleTDPageChange = (newTDPage) => {
      if (newTDPage >= 1 && newTDPage <= Math.ceil(10 / 5)) {
        setcurrentTDPage(newTDPage);
      }
  };

  // Top domain Total page calculation
  const totalTDPages = Math.ceil(10 / 5);

  // Auto Refresh 
  useEffect(() => {
    if (optionrefresh !== 'Dont refresh'){
        const refreshValues = {
          'every 1 minutes': 1,
          'every 5 minutes': 5,
          'every 10 minutes': 10,
          'every 30 minutes': 30,
          'every 60 minutes': 60,
        };
        const refreshValue = refreshValues[optionrefresh];
        const intervalId = setInterval(() => {
          dashboardstats();
          maliciousTable();
          TopdomainData();
          ToptalkerData();
        }, refreshValue * 60 * 1000);
        return () => clearInterval(intervalId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, optionright, query, optionrefresh]);

    
  // Page Jump and Toast
  const [jumpToPage, setJumpToPage] = useState("");
  const [Toast, settoast] = useState(false);
  const [Toastmessage, setToastmessage] = useState("");
  const handletoast = () => {
    settoast(false);
  };
 
return (
  <section className='w-full h-full space-y-4 p-2'>
    {isLoading && (<Loading/>)}
    {serverError ? (
      <ServerError/>
    ) : (
      <>
        {/* Common Header */}
      <Header
        optionsleft={optionsleft}
        optionstimestamp={optionstimestamp}
        optionsrefresh={optionsrefresh}
      
        optionleft={optionleft}
        optionright={optionright}
        optiontimestamp={optiontimestamp}
        optionrefresh={optionrefresh}
        
        isDropdownOpenLeft={isDropdownOpenLeft}
        isDropdownOpenRight={isDropdownOpenRight}
        isDropdownOpenTimestamp={isDropdownOpenTimestamp}
        isDropdownOpenRefresh={isDropdownOpenRefresh}
      
        dropdownRefLeft={dropdownRefLeft}
        dropdownRefRight={dropdownRefRight}
      
        handleOptionClickLeft={handleOptionClickLeft}
        handleOptionClickRight={handleOptionClickRight}
        handleOptionClickTimestamp={handleOptionClickTimestamp}
        handleOptionClickRefresh={handleOptionClickRefresh}
        handleOptionClickRightButton={handleOptionClickRightButton}
      
        handleOptionLeft={handleOptionLeft}
        handleOptionTimestamp={handleOptionTimestamp}
        handleOptionRefresh={handleOptionRefresh}
      
        setSearchQuery={setSearchQuery}
        searchquery={searchquery}
        handleSearch={handleSearch}
        searchPlaceholder={searchPlaceholder}
        handleKeyPress={handleKeyPress}
      />

        {/* Toast */}
        {isErrorToast ? (
          <div className="flex items-center justify-center">
            <div className='rounded-md px-10 py-2 bg-red-100/90 w-max inline-flex'>
              <svg fill="#B91C1C" className='-mb-3' xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24">
                <path d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "></path>
              </svg>
              <p className="text-gray-950 font-medium">{toastMessage}</p>
            </div>
          </div>
        ) : (
          <>
            {/* Stats */}
            <Stats tcount={tcount} mcount={mcount} mode={mode} avg_query={avg_query} intel={intel}/>

            {/* Bar and Pie CHarts */}
            <div className='flex w-full h-[420px] gap-3'>
              <div className='w-[65%] h-full'>
                <Areachart areaData={areaData}/>
              </div>
              <div className='w-[35%] h-full'>
                <Piechart  category={categorydata} count={categorycount}/>
              </div>
            </div>

            {/* Table */}
            {shouldRenderTable && (
            <Table
              mtable={mtable}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              pageSize={pageSize}
              handlePageSizeChange={handlePageSizeChange}
              pageSizeOptions={pageSizeOptions}
              totalPages={totalPages}
              hasTableData={hasTableData}
            />
          )}

            {/* Table2 */}
            <Table2
              currentTTPage={currentTTPage}
              ttData={ttData}
              hasTableTTData={hasTableTTData}
              jumpToPage={jumpToPage}
              setJumpToPage={setJumpToPage}
              currentTDPage={currentTDPage}
              tdData={tdData}
              hasTableTDData={hasTableTDData}
              totalTDPages={totalTDPages}
              handleTDPageChange={handleTDPageChange}
              totalTTPages={totalTTPages}
              handleTTPageChange={handleTTPageChange}
              handleJumpToPage={handleJumpToPage}
              Toast={Toast}
              Toastmessage={Toastmessage}
              handletoast={handletoast}
            />
          </>
        )}
      </>
    )}
    <div className='pt-[1px]'></div>
  </section>
 )
}

