import React, { useEffect, useState } from 'react'
import axios from '../../API/axios';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../Common/Loading/loading';
import ServerError from '../../ServerError/serverdown';

export default function Stats() {
    
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        setIsLoading(true);
        axios.get("/api/v1/checkreputation/data",
        {withCredentials: true}
        ).then(function(response){
            setData(response.data.threat_count);
            setIsLoading(false);
        }).catch(function(error){
            if (error.response){
                if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                  navigate("/")
                } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                  navigate("/")
                } else{
                  setServerError(true);
                }
              } else{
                setServerError(true);
              }
              setIsLoading(false);
        });

    },[navigate])
      
  return (
    <>
     {isLoading && (<Loading/>)}
        {serverError ?(
          <ServerError/>
        ):(
            <>
                <div className="w-full bg-white shadow-default rounded-md">
                    <header className="px-5 py-4 border-b border-gray-100">
                        <h2 className="text-lg font-extrabold text-gray-800">Threat Category Stats</h2>
                    </header>
                    <div className="overflow-x-auto">
                      {/* Table start */}
                       <table className="table-auto w-full  items-center">
                          <thead className="text-sm font-semibold uppercase text-gray-600 bg-gray-100/75">
                             <tr>
                                <th className="p-2 pl-24 w-1/4">
                                   <div className="font-semibold text-left">Threat Category</div>
                                </th>
                                <th className="p-2">
                                   <div className="font-semibold text-center">Threat Score</div>
                                </th>
                                <th className="p-2">
                                   <div className="font-semibold text-center">Count</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">Update Status</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">Info</div>
                                </th>
                             </tr>
                          </thead>
                          <tbody className="text-sm divide-y divide-gray-100">
                             {data.map((item) => (
                             <tr key={item.category}>
                                 <td className="p-2 pl-36">
                                     <div className="flex items-center">
                                         <div className="font-semibold text-gray-800">{item.category}</div>
                                     </div>
                                 </td>
                                 <td className="p-2 text-center">{item.score}</td>
                                 <td className="p-2 text-center justify-center flex-1">{item.count}</td>
                                 <td className="">
                                     <div className='flex justify-center p-3'>
                                         <div className="font-medium bg-primary-300/20 text-primary-500 w-max py-1 px-2.5 rounded-xl">
                                             <Moment fromNow ago locale='in'>{item.timestamp}</Moment> ago</div>
                                         </div>
                                     </td>
                                 <td className="p-3 flex items-center justify-center">
                                     <Link to='/docs' className="text-lg bg-slate-100 w-max rounded-full p-2">
                                         <svg className="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
                                         </svg>
                                     </Link>
                                 </td>
                             </tr>))}
                          </tbody>
                       </table>
                    </div>
                </div>
            </>
         )}
    </>
    )
 }
