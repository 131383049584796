import { useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import axios from '../../../API/axios';
import Loading from '../../../Common/Loading/loading';
import ServerError from '../../../ServerError/serverdown';
import ErrorToast from '../../../Common/Toast/errortoast';
import SuccessToast from '../../../Common/Toast/successtoast';
import { useNavigate } from 'react-router-dom';

export default function Password() {
  const [currentpassword, setCurrentPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [isErrorToast, setIsErrorToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();

  // password visibility state
  const [currentpasswordVisibility, setCurrentPasswordVisibility] = useState(false);
  const [newpasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmpasswordVisibility, setConfirmPasswordVisibility] = useState(false);

  // Validation status for each password contains rule
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
  const [isLowerCaseValid, setIsLowerCaseValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSpecialValid, setIsSpecialValid] = useState(false);

  // Current password handle
  const handleCurrentPassword = () => {
    setCurrentPasswordVisibility(!currentpasswordVisibility);
  };

  // Handle New password
  const handleNewPassword = () => {
    setNewPasswordVisibility(!newpasswordVisibility);
  };

  // Handle confirm password
  const handleConfirmPassword = () => {
    setConfirmPasswordVisibility(!confirmpasswordVisibility);
  };

  const handlePasswordChange = (value) => {
    // Validate password contains rules
    setIsLengthValid(value.length >= 8);
    setIsUpperCaseValid(/[A-Z]/.test(value));
    setIsLowerCaseValid(/[a-z]/.test(value));
    setIsNumberValid(/\d/.test(value));
    setIsSpecialValid(/[!@#$%^&*()\-=_+]/.test(value));
  };

//   Handle Passworf and toast message
  const handleUpdatePassword = () => {
    if (currentpassword.length === 0){
        showErrorToast(" Please enter your old password");
    }else if(newpassword.length ===0){
        showErrorToast("Please enter your new password");
    }else if(confirmpassword.length ===0){
        showErrorToast("Please enter  confirm password");
    }else if(newpassword !== confirmpassword){
        showErrorToast("Password Does not match");
    } else if (!(isLengthValid && isLowerCaseValid && isNumberValid && isSpecialValid && isUpperCaseValid)){
        showErrorToast("New password must be combination of alphanumeric!")
    } else {
        setIsLoading(true);
        axios.put('/api/v1/update/password',{
          old_password: currentpassword, 
          new_password: newpassword
        },{withCredentials: true})
        .then(function (response) {
            console.log(response)
            if (response.data.message === 'password is updated successfully.'){
                showSuccessToast("Your password has been updated successfully");
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                showErrorToast("Password is not update");
            }
            setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error)
            if (error.response){
               if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
                showErrorToast("Too many request try after 1 hour");
                } else if (error.response.data.message === 'invalid current password' && error.response.status === 401){
                    showErrorToast("Invalid current Password");
                }else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                    navigate("/")
                } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                  navigate("/")
                } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                  navigate("/")
                } else{
                  setServerError(true);
                }
              } else{
                setServerError(true);
              }
              setIsLoading(false);
        });
    }
  }
  


    // Toast Success
    const showSuccessToast = (message) => {
        setToastMessage(message);
        setIsSuccessToast(true);
    
        setTimeout(() => {
          setIsSuccessToast(false);
        }, 5000);
      };
    
      // Toast Error
      const showErrorToast = (message) => {
        setToastMessage(message);
        setIsErrorToast(true);
    
        setTimeout(() => {
          setIsErrorToast(false);
        }, 5000);
      };
        


  return (
    <>
     {isLoading && (<Loading/>)}
    {serverError ?(
      <ServerError/>
      ):(
      <>
    <section className='w-full h-full'>
        <div>
            <h1 className='text-gray-900 font-bold text-xl'>User Password Details</h1>
            <p className='py-1.5'>Update your password with current password.</p>
        </div>
        <hr className='border-gray-300/80 my-2'/>
        <div className='flex space-x-20 text-gray-600 font-semibold py-4'>
            <span>Change Password</span>
            <div className="space-y-2 mt-1">
            
                {/* current password */}
                <div className='flex flex-col'>
                    <label htmlFor='currentpassword' className='block py-2 text-sm font-medium text-gray-900 outline-none'>Current Password</label>
                    <div className='relative'>
                        <span className='absolute right-3 top-2 w-5 h-5 cursor-pointer' onClick={handleCurrentPassword}>
                        {currentpasswordVisibility ? <IoEyeOutline /> : <IoEyeOffOutline />}
                        </span>
                        <input
                        id='currentpassword'
                        type={currentpasswordVisibility ? 'text' : 'password'} 
                        name='currentpassword'
                        className='outline-none bg-transparent border border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-1.5 p-2.5'
                        placeholder='current password'
                        required=''
                        value={currentpassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        autoComplete='off'
                        />
                    </div>
                </div>

                {/* New password */}
                <div className='flex flex-col '>
                    <label htmlFor="newpassword" className='block py-2 text-sm font-medium text-gray-900'>New Password</label>
                    <div className='relative'>
                            <span className='absolute right-3 top-2 w-5 h-5 cursor-pointer' onClick={handleNewPassword}>
                                {newpasswordVisibility ? <IoEyeOutline /> : <IoEyeOffOutline />}
                            </span>
                                <input
                                id='newpassword'
                                type={newpasswordVisibility ? 'text' : 'password'}
                                name='newpassword'
                                className=' outline-none bg-transparent border border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-1.5 p-2.5'
                                placeholder='new password'
                                required=''
                                value={newpassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    handlePasswordChange(e.target.value);
                                  }}
                                autoComplete='off'
                                />
                    </div>
                </div>
                {/* confirm password */}
                <div className='flex flex-col '>
                        <label htmlFor="confirmpassword"  className='block py-2 text-sm font-medium text-gray-900'>Confirm Password</label>
                        <div className='relative'>
                                <span className='absolute right-3 top-2 w-5 h-5 cursor-pointer' onClick={handleConfirmPassword}>
                                    {confirmpasswordVisibility ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                </span>
                                    <input
                                    id='confirmpassword'
                                    type={confirmpasswordVisibility ? 'text' : 'password'}
                                    name='confirmpassword'
                                    className=' outline-none bg-transparent border border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-1.5 p-2.5'
                                    placeholder='confirm password'
                                    required=''
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    autoComplete='off'
                                    />
                        </div>
                </div>
            </div>
        {/*Paswword Condiditons  */}
        <div className='space-y-3'>
            <p>Password must contain:</p>
            <div className='flex items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isLengthValid ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
            </svg>
            <p className={`text-sm font-normal ${isLengthValid ? 'text-green-500' : 'text-red-500'}`}>
                At least 8 characters long
            </p>
            </div>
            <div className='flex items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isUpperCaseValid ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
            </svg>
            <p className={`text-sm font-normal ${isUpperCaseValid ? 'text-green-500' : 'text-red-500'}`}>
                At least 1 upper case letter (A-Z)
            </p>
            </div>
            <div className='flex items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isLowerCaseValid ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
            </svg>
            <p className={`text-sm font-normal ${isLowerCaseValid ? 'text-green-500' : 'text-red-500'}`}>
                At least 1 lower case letter (a-z)
            </p>
            </div>
            <div className='flex items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isNumberValid ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
            </svg>
            <p className={`text-sm font-normal ${isNumberValid ? 'text-green-500' : 'text-red-500'}`}>
                At least 1 number (0-9)
            </p>
            </div>
            <div className='flex items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isSpecialValid ? 'text-green-500' : 'text-red-500'}`} aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
            </svg>
            <p className={`text-sm font-normal ${isSpecialValid ? 'text-green-500' : 'text-red-500'}`}>
                At least 1 special character
            </p>
            </div>
        </div>
        </div>
        
        {/* Change Password Button */}
        <hr className='border-gray-300/80 my-2'/>
        <div className='flex justify-end mt-4'>
            <button onClick={handleUpdatePassword} className="text-white font-semibold bg-sky-600 hover:bg-sky-700 outline-none rounded-md px-6 py-2.5 text-center">Change Password</button>
        </div>

            {/* Toast */}
            {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
        )}
        {isSuccessToast && (
          <SuccessToast toastMessage={toastMessage}/>
        )}
    </section>
     </>)}
     </>
  )
}

