import React, { useEffect, useState } from 'react'
import axios from "../../../API/axios";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Loading from '../../../Common/Loading/loading';
import ServerError from '../../../ServerError/serverdown';
import ErrorToast from '../../../Common/Toast/errortoast';
import SuccessToast from '../../../Common/Toast/successtoast';
import { useNavigate } from 'react-router-dom';

export default function Email() {

    const [email, setEmail] = useState('');
    const [newemail, setNewEmail] = useState('');
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [password, setPassword] = useState('');
    const [isErrorToast, setIsErrorToast] = useState(false);
    const [isSuccessToast, setIsSuccessToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const navigate = useNavigate();

    
    // password visibility state
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    // password handle
    const handlePassword = () => {
    setPasswordVisibility(!passwordVisibility);
    };

    //update email api
    useEffect(()=> {
        setIsLoading(true);
            axios.get("/api/v1/email/data",
            {withCredentials: true})
            .then(function(response){
                setEmail(response.data.email);
                setIsLoading(false);
            }).catch(function(error){
                setServerError(true);
                setIsLoading(false);
            })
    },[])

    const handleEmailChange = (e) => {
     
        e.preventDefault();
        if (newemail.length === 0) {
            showErrorToast("Please Provide New Email !");
        } else if (!emailRegex.test(newemail)) {
            showErrorToast("Please Enter a valid email !");
        } else if (password.length === 0) {  
            showErrorToast("Please Enter Password !");
        } else {
            setIsLoading(true);
            axios.put("/api/v1/update/email", {
                new_email: newemail,
                password: password 
            },{withCredentials: true}
            ).then(function (response) {
                if (response.data.message === 'email is sent' && response.status === 200){
                    showSuccessToast("Verify your new email by clicking the link sent to your inbox.");
                    setNewEmail('');
                    setPassword('');
                }
                setIsLoading(false);
            }).catch(function (error) {
                if (error.response){
                    if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
                        showErrorToast("Too many request try after 1 hour");
                    } else if (error.response.data.message === 'invalid current password' && error.response.status === 401){
                        showErrorToast("Invalid current Password");
                    } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
                        navigate("/")
                    }else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
                      navigate("/")
                    } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
                      navigate("/")
                    } else{
                      setServerError(true);
                    }
                  } else{
                    setServerError(true);
                  }
                  setIsLoading(false);
            });
        }
    };

    // Toast success
    const showSuccessToast = (message) => {
        setToastMessage(message);
        setIsSuccessToast(true);
    
        setTimeout(() => {
          setIsSuccessToast(false);
        }, 5000);
      };
    
    //   Toast Error
      const showErrorToast = (message) => {
        setToastMessage(message);
        setIsErrorToast(true);
    
        setTimeout(() => {
          setIsErrorToast(false);
        }, 5000);
      };

  return (
    <>
    {isLoading && (<Loading/>)}
    {serverError ?(
      <ServerError/>
      ):(
        <>
    <section className='w-full h-full'>
        <div>
            <h1 className='text-gray-900 font-bold text-xl'>Email Details</h1>
            <p className='py-1.5'>Update your email address with current.</p>
        </div>
        <hr className='border-gray-300/80 my-2'/>
        <form className='flex space-x-20 text-gray-600 font-semibold w-full py-4'>
            <div className='font-normal space-y-4 text-gray-700'>
                <p>
                To initiate an email change, please enter your desired <br/> 
                new email address below. A verification email<br/>
                 will be sent to this address to ensure its validity<br/>
                  before the change is implemented. </p> <br/>
                  <p>Please check your inbox for the verification <br/>
                   email and follow the instructions provided to <br/>
                    complete the process.
                </p>
            </div>
            {/* Current Email */}
            <div className="space-y-2 mt-1 w-1/4">
              <div>
                  <p id="currentemail" className="block mb-2 text-sm font-medium text-gray-900">Current Email</p>
                  <input name="currentemail" type="email" value={email} className="bg-slate-100/80 border border-gray-500 outline-none text-gray-600 placeholder:text-gray-600 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-2 px-2.5" required="" disabled/>
              </div>
              {/* New Email */}
              <div>
                  <p id="newemail" className="block py-2 text-sm font-medium text-gray-900">New Email</p>
                  <input name="newemail" type="email" placeholder="user@company.com" className="bg-transparent border outline-none border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-2 px-2.5" value={newemail} onChange={(e) => setNewEmail(e.target.value)} required="" autoComplete='off'/>
              </div>
              {/* Current Password */}
              <div className='flex flex-col mb-6'>
                <label htmlFor='password' className='block py-2 text-sm font-medium text-gray-900'>Current Password</label>
                  <div className='relative'>
                    <span className='absolute right-3 top-2 w-5 h-5 cursor-pointer' onClick={handlePassword}>
                        {passwordVisibility ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </span>
                <input id='password' type={passwordVisibility ? 'text' : 'password'} name='password' className='bg-transparent outline-none border border-gray-500 text-gray-900 placeholder:text-gray-400 text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full py-1.5 p-2.5' placeholder='current password'required='' value={password} onChange={(e) => setPassword(e.target.value)}autoComplete='off'/>
                    </div>
                </div>
            </div>
        </form>
        <hr className='border-gray-300/80 my-2'/>
        <div className='flex justify-end mt-4'>
        <button className="text-white font-semibold bg-sky-600 hover:bg-sky-700 focus:ring-4 focus:outline-none focus:ring-sky-300 rounded-md px-6 py-2.5 text-center" onClick={handleEmailChange}>Change Email</button>
        </div> 
          {/* Toast */}
          {isErrorToast && (
          <ErrorToast toastMessage={toastMessage}/>
        )}
        {isSuccessToast && (
          <SuccessToast toastMessage={toastMessage}/>
        )}
    </section>
    </>)}
    </>
  )
}




