import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { useRef, useEffect } from "react";
import ApexCharts from "apexcharts";

const Highriskstats = ({ chartdata, dnscount, tldcount, record, recordcount }) => {
  const donutChartRef = useRef(null);

  const series = chartdata
    ? [
        {
          name: "Queries",
          
          data: chartdata.map((item) => [new Date(item[0]).getTime(), item[1]]),
        },
      ]
    : [];

    const options = {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        colors: "#FF0000",
        zoom: {
          autoScaleYaxis: true,
        },
        fill: {
          type: 'gradient',
        },
       
        
      },
      title: {
        text: "High Risk DNS Traffic Distribution",
        align: "left",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          color: "#263238",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    };

    // Donut Chart
  useEffect(() => {
    const donutChartOptions = {
      series: recordcount,
      chart: {
        height: 300,
        width: "100%",
        type: "donut",
      },
      zoom: {
        enabled: true,
        type: "x",
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: "#fff",
          strokeColor: "#37474F",
        },
        selection: {
          background: "#90CAF9",
          border: "#0D47A1",
        },
      },
      stroke: { colors: ["transparent"], lineCap: "" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Total Count ",
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return `${sum}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
            size: "70%",
          },
        },
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      grid: { padding: { top: -2 } },
      labels: record,
      dataLabels: { enabled: false },
      legend: { position: "bottom", fontFamily: "Inter, sans-serif" },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value + "k";
          },
        },
        axisTicks: { show: false },
        axisBorder: { show: false },
      },
    };

    if (document.getElementById("donut-chart") && typeof ApexCharts !== "undefined") {
      if (!donutChartRef.current) {
        const donutChart = new ApexCharts(document.getElementById("donut-chart"), donutChartOptions);
        donutChart.render();
        donutChartRef.current = donutChart;
      }
    }

    return () => {
      if (donutChartRef.current) {
        donutChartRef.current.destroy();
        donutChartRef.current = null;
      }
    };
  }, [record, recordcount]);

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col-2 space-x-5 w-full ">
        <div className="flex w-full  gap-3">
          <div className="flex flex-col w-[25%]">
            <div className="flex w-full gap-2">
              <div className="w-1/2 h-28 py-6 overflow-hidden bg-white bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-rose-500 via-red-400 to-red-500 rounded-xl shadow-lg duration-300 hover:shadow-default group">
                <div className="text-gray-100">
                  <p className="text-2xl text-center font-semibold">DNS Count</p>
                </div>
                <h1 className="text-3xl font-bold text-center mt-2 text-gray-50">
                  <CountUp start={0} end={dnscount} delay={0} />
                </h1>
              </div>
              <div className="w-1/2 h-28 py-6 overflow-hidden bg-white bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-rose-500 via-red-400 to-red-500 rounded-xl shadow-lg duration-300 hover:shadow-default group">
                <div className="text-gray-100">
                  <p className="text-2xl text-center font-semibold">TLD Count</p>
                </div>
                <h1 className="text-3xl font-bold text-center mt-2 text-gray-50">
                  <CountUp start={0} end={tldcount} delay={0} />
                </h1>
              </div>
            </div>
            <div className="pb-3 bg-white rounded-md shadow-default mt-5">
              <h5 className="text-lg font-bold text-gray-900 py-2.5 pl-5">Count By Category</h5>
              <hr className="border-slate-300" />
              {record.length > 0 ? (
                <div id="donut-chart" className="py-4"></div>
              ) : (
                <p className="text-gray-800 text-center py-40">No data</p>
              )}
            </div>
          </div>
          <div className="flex-1 ">
            <div id="chart-timeline" className="  px-2 pb-2 pt-3 rounded-md bg-white shadow-default">
              <Chart options={options} series={series} type="area" height={455} chartdata={chartdata} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highriskstats;
