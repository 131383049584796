import React from 'react'
import Lottie from "lottie-react";
import Processing from "../../../Assets/Processing.json";

export default function Loading() {
  return (
    <div className="fixed inset-0 z-50 bg-slate-100 bg-opacity-80 transition-opacity flex items-center justify-center">
    <div className="text-center items-center ">
         <Lottie animationData={Processing} loop={true} className="-mt-36"/>
             <h3 className="text-xl font-semibold text-slate-500">Please wait...</h3>
    </div>
</div> 
  )
}
