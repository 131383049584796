import React, { useState, useRef, useEffect } from 'react'
import Disable2fa from './Two-Factor/disable2fa'
import Enable2fa from './Two-Factor/enable2fa'
import Lottie from 'lottie-react';
import animationData from '../../../../Assets/success.json';
import axios from '../../../API/axios';
import ServerError from '../../../ServerError/serverdown';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import SuccessToast from '../../../Common/Toast/successtoast';


export default function Security() {

    const [isEnablePopup, setIsEnablePopup] = useState(false);
    const [isDisablePopup, setIsDisablePopup] = useState(false);
    const [isSuccessPopup,setIsSuccessPopup] = useState(false);
    const [secretKey, SetSecretKey] = useState('');
    const [qrcode, setQRCode] = useState('');
    const [isTwoFactorConfigured, setIsTwoFactorConfigured]  = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const navigate = useNavigate();
    const [isErrorToast, setIsErrorToast] = useState(false);
    const [isSuccessToast, setIsSuccessToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [recoveryKey, setRecoveryKey] = useState([]);
    const [isRecoveryKey, setIsRecoveryKey] = useState(false);

    // Success Toast
  const showSuccessToast = (message) => {
    setToastMessage(message);
    setIsSuccessToast(true);

    setTimeout(() => {
      setIsSuccessToast(false);
    }, 5000);
  };
//  Error Toast
  const showErrorToast = (message) => {
    setToastMessage(message);
    setIsErrorToast(true);

    setTimeout(() => {
      setIsErrorToast(false);
    }, 5000);
  };
    
 // 2FA State API 
    const handleState = () => {
      setIsLoading(true);
      axios.get('/api/v1/twofactor/state',
      {withCredentials: true})
      .then(function (response){
        setIsTwoFactorConfigured(response.data.result);
        setIsLoading(false);
      })
      .catch(function (error){
        if(error){
            if (error.response.data.msg === 'Missing Authorization Header') {
                navigate('/')  
            } else{ 
              setServerError(true)
            }  
          setIsLoading(false);
        }
      });
    }
   
    // Handle Enable and API
    const handleEnable = () =>{
        setIsEnablePopup(!isEnablePopup);
       axios.get('/api/v1/twofactor/data',
       {withCredentials: true})
       .then(function (response){
           SetSecretKey(response.data.secret);
           setQRCode(response.data.qrcode);
           setIsLoading(false);
       })
       .catch(function (error){
          if(error){
            if (error.response.status === 422) {
                navigate('/')  
            } else{ 
               setServerError(true)
            }  
          setIsLoading(false);
        };
       });
        handleState();
    }

    // Handle Disable
    const handleDisable = () =>{
        setIsDisablePopup(!isDisablePopup);
        handleState();   
    }
     
    // Handle Success Popup
  const handleOnSuccess = () => {
    handleState();
    setIsSuccessPopup(false);
  }

  useEffect(()=>{
    handleState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  const [otp, setOtp] = useState(new Array(6).fill(""));
  
  const inputRefs = useRef([]);
  let newOtp

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Move to next input if current field is filled
    if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  // Twofactor API and  qrcode 
  const TwoFactorEnable = (event) =>{
    event.preventDefault();
    const OTP = otp.join("");
    if (OTP.length === 0){
        showErrorToast('please enter code!');
    } else if(OTP.length < 6){
      showErrorToast('Please enter valid otp and try again.');
    } else {
      setIsLoading(true);
      axios.post('/api/v1/twofactor/enable',{
        secret: secretKey, otpcode: OTP
      },{withCredentials: true})
      .then(function (response){
        if(response.status === 200 && response.data.message === "otp is valid"){
          setIsEnablePopup(false);
          setRecoveryKey([response.data.recovery_key]);
          setIsRecoveryKey(true);
        } 
        setIsLoading(false);
      })
      .catch(function (error){
        if (error.response) {
          if (error.response.data.message === 'try after 1 hour' && error.response.status === 429){
            showErrorToast("Too many request try after 1 hour");
          } else if (error.response.status === 401 && error.response.data.message === 'invalid otp') {
            showErrorToast('Invalid OTP. Please try again.'); 
          } else if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            navigate("/")
          } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            navigate("/")
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            navigate("/")
          } else{
            setServerError(true);
          }
        }
        setIsLoading(false);
      });
    }
  }

  // Handle copy icon click
  const handleCopyClick = () => {
    const textToCopy = secretKey;
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    showSuccessToast("Secret code copied!")
  };

  

  const handleCodeCopy = () => {
    const allCodesToCopy = recoveryKey.join('\n');
    navigator.clipboard.writeText(allCodesToCopy).then(
      function () {
        showSuccessToast('Codes copied!');
      },
      function (err) {
        showErrorToast('Unable to copy codes', err);
      }
    );
  };

  const handleDownload = () => {
    const textContent = recoveryKey.join('\n');
    const blob = new Blob([textContent], { type: 'text/plain' });
    saveAs(blob, 'recovery_codes.txt');
  };

  const handleFinish = () =>{ 
    setIsLoading(true);
    axios.put("/api/v1/twofactor/recoverykey",{
      secret: secretKey,
      recoverykey: recoveryKey[0]
    },{withCredentials: true}
    ).then(function(response){
      setIsRecoveryKey(false);
      setIsSuccessPopup(true);
      setIsLoading(false);
    }).catch(function(error){
      if (error.response) {
        if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
          navigate("/")
        } else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
          navigate("/")
        } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
          navigate("/")
        } else{
          setServerError(true);
        }
      }
      setIsLoading(false);
    })
  }

  return (
    <>
   {serverError ?(
     <ServerError/>
     ):(
       <>
    <div className='w-full h-full'>
            <div className="w-full bg-sky-100 rounded-lg shadow-sm p-5 border-dashed border border-sky-500">
                <div className="flex justify-start items-center gap-4">
                    <div className="bg-sky-200 flex p-2 rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-sky-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <div className="text-center sm:text-left space-y-2">
                        <h1 className="text-gray-700 font-bold tracking-wider">Enhance the Security of your account with Two-Factor Authentication(2FA)</h1>
                        <p className="text-gray-500 font-semibold">
                        For added protection, enable two-factor authentication on your account. When logging in, you will be required to enter not<br/> only your password but also a unique 6-digit verification code sent to your trusted device.</p>
                        <p>
                             This additional step ensures that even if your password is compromised, unauthorized users cannot access<br/> your account without the verification code.
                        </p>
                    </div>
                </div>
            <div>
              {/* Enable dissable button */}
            <div className='flex justify-end'>
                {isTwoFactorConfigured ? ( 
                  <button onClick={handleDisable} className="bg-red-500/80 py-2 px-10 mt-6 text-white font-bold rounded-md">Disable</button>
                ):( 
                  <button onClick={handleEnable} className="bg-sky-500 py-2 px-10 mt-6 text-white font-bold rounded-md hover:bg-sky-600">Enable</button>
                )}
            </div>
        </div>
        </div>
        {isEnablePopup && (<Enable2fa actionClose={handleEnable} secretKey={secretKey} qrcode={qrcode} handleCopyClick={handleCopyClick}
         inputRefs={inputRefs} handleChange={handleChange} handleClick={handleClick} handleKeyDown={handleKeyDown} otp={otp} TwoFactorEnable={TwoFactorEnable} isLoading={isLoading} isErrorToast={isErrorToast} 
        isSuccessToast={isSuccessToast} toastMessage={toastMessage}/>)}
        
        {isDisablePopup && (<Disable2fa actionClose={handleDisable} handleEnable={handleEnable}/>)}
        {isRecoveryKey && (
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-400 bg-opacity-50 dark:bg-gray-500 dark:bg-opacity-75 shadow-xl transition-opacity"></div>
                    <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                          <div className="relative transform overflow-hidden rounded-lg p-8 bg-white shadow-default  transition-all h-max w-[47%]">
                          <h1 className="mb-4 text-[26px] text-gray-800 font-semibold">Recovery Key</h1>
                            <p className="text-gray-500 font-semibold">
                            "Your recovery key is a safety net that can be utilized to regain access to your account if you lose your device."
                            </p>
                            <p className="text-gray-500 font-semibold mt-2">
                                We strongly recommend that you copy your recovery codes and keep them safe.
                            </p>
                            <div className="py-6  gap-4 flex">
                                <div className="py-4 px-4 w-[47%] text-lg bg-slate-50 border rounded-md">
                                  {recoveryKey.map((code, index) => (
                                      <p key={index}>{code}</p>
                                  ))}
                                </div>
                                <div className="space-x-4 w-2/3">
                                <p className="text-gray-500 mt-3 ml-4 pb-4">
                                    If you lose access and you don't have configured a phone backup number, using these codes will be the only way
                                    to recover your account
                                </p>
                                <button className="px-6 py-2  hover:bg-transparent hover:text-gray-600 border bg-slate-100 text-gray-700 border-gray-400 rounded-md font-semibold" onClick={handleDownload}>
                                    Download  
                                </button>
                                <button className="px-7 py-2 hover:bg-transparent hover:text-gray-600 border bg-slate-100 text-gray-700 border-gray-400 rounded-md font-semibold"onClick={handleCodeCopy}>
                                    Copy
                                </button>
                                </div>
                            </div>
                            <div className='justify-end items-end flex'>
                                <button onClick={handleFinish} className="px-20 py-2.5 bg-primary-500 text-white rounded-md hover:bg-primary-600">Finish</button>
                            </div>
                        </div>
                    </div>
                </div>
                {isSuccessToast && <SuccessToast toastMessage={toastMessage} />}
            </div>
      )}
        {isSuccessPopup && (
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-400 bg-opacity-50 dark:bg-gray-500 dark:bg-opacity-75 shadow-xl transition-opacity"></div>
              <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg px-20 bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-max dark:bg-gray-800 dark:border-gray-700 dark:border">
                        <div className="p-2 md:mx-auto">
                          <Lottie
                              animationData={animationData}
                              loop={false}
                              autoplay={true}
                              className='w-56 h-56 mx-auto'
                            />
                          <div className="text-center">
                              <h3 className="md:text-2xl text-base -mt-3 text-gray-900 font-semibold text-center dark:text-white">Two-Factor Authentication!</h3>
                              <p className="text-gray-600 my-2 dark:text-gray-200">Your two factor authentication is<br/> successfully configured.</p>
                              <button onClick={() => handleOnSuccess()} className="inline-flex w-full my-6 justify-center cursor-pointer rounded-md bg-primary-500 text-sm text-white font-semibold py-2.5 px-10 shadow-sm hover:bg-primary-600 sm:ml-3 sm:w-auto">Done</button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
          )}  

     </div>
     </>)}
    </>
  )
}
