import React from 'react'
import { AppTitle } from '../../Common/Helmet/helmet'

export default function Documentation() {

  AppTitle("Documentation | DNS Shield")
  return (
  <section className='bg-gradient-to-t from-rose-50 to-teal-50 w-full p-6 space-y-6 '>
    <h1 className='text-3xl font-extrabold'>Shreshta Threat Category Documentation</h1>
    <div className='space-y-2'>
    <h2 className='text-xl font-bold'>Introduction:</h2>
    <div className='space-y-2'>

    <p>DNS Shield is a DNS server feature that allows to overlay custom information on top of the global DNS to provide alternate responses to DNS queries.</p>
    <p>The prime motivation for creating this feature was to protect users from badness on the Internet related to known-malicious global identifiers such as host names, domain names, IP addresses, or nameservers.</p>
    <p>Criminals tend to keep using the same identifiers until they are taken away from them. Unfortunately, the Internet security industry's ability to take down criminal infrastructure at domain registries, hosting providers or ISPs is not timely enough to be effective.</p>
    <p>if you use protective dns, it adds extra layer of security to your network and which will block the malicious dns traffic on a real-time basis.</p>
    </div>
    
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>BFSI:</h2>
      <p>This threat feed contains domain names identified as banking, financial services and insurance(BFSI).</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Bogons:</h2>
      <p>This feed contains IP addresses that have yet to be assigned to an entity and should not generate any incoming or outgoing traffic.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Botnet:</h2>
      <p>This threat feed contains domain names identified as hosting a botnet resource (not a botnet C&C).</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>C2 (Command and control):</h2>
      <p>This threat feed contains domain names identified as Command & Control(C2).</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Crypto-jacking:</h2>
      <p>This threat feed contains multiple lists of IP addresses and domains that are hosting crypto-jacking scripts, which utilize the resources of an end user's computer to mine cryptocurrency.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>DGA (Domain generation algorithms):</h2>
      <p>This threat feed contains dynamically generated domain names(DGA) identified to be associated with malware.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>DOH (DNS over HTTPS):</h2>
      <p>This policy feed contains a list of DoH resolver endpoint addresses.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Hijacked-IPs:</h2>
      <p>This threat feed contains IP addresses which are identified as being hijacked, belonging to bulletproof hosters, or being leased by professional malicious organizations.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Malware:</h2>
      <p>This threat feed contains domain names identified as hosting malware.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>NOD (Newly Observed Domains):</h2>
      <p>This policy feed contains domain names that were registered sometime ago but have become active now.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Nrd-1m:</h2>
      <p>This policy feed contains domain names registered in the last month.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Nrd-1w:</h2>
      <p>This policy feed contains domain names registered in the last one week.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Phishing:</h2>
      <p>This threat feed contains domain names identified as hosting a phishing website.</p>
    </div>
    <div className='space-y-2'>
      <h2 className='text-xl font-bold'>Tor-exit-nodes:</h2>
      <p>This threat feed contains a list of known Tor Exit Nodes.</p>
    </div>
  </section>
  )
}
