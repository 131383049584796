import { Link } from 'react-router-dom';
import Loading from '../../../../Common/Loading/loading';
import ErrorToast from '../../../../Common/Toast/errortoast';
import SuccessToast from '../../../../Common/Toast/successtoast';

export default function Enable2fa({
  actionClose,
  secretKey,
  qrcode,
  inputRefs,
  handleChange,
  handleKeyDown,
  handleClick,
  otp,
  TwoFactorEnable,
  isLoading,
  isErrorToast,
  isSuccessToast,
  toastMessage,
  handleCopyClick
}) { 

  return (
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-300 bg-opacity-75 shadow-xl transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          {isLoading && <Loading />}
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative p-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-max">
              <div className="flex items-center justify-between pb-2 border-b rounded-t">
                <h3 className="text-xl font-bold text-gray-900">Two-Factor Authentication</h3>
                <button onClick={actionClose} className="bg-transparent hover:bg-gray-200 text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="space-y-3 py-3 pb-4 border-b rounded-t">
                <h1>Set up two-factor authentication.</h1>
                <p className="text-sm leading-relaxed text-gray-700">
                  To be able to authorize transactions you need to scan<br />
                  this QR Code with your Google Authentication App or <br />
                  Authy App and enter the verification code below.
                </p>
              </div>
              <div className='flex flex-col items-center mt-1'>
                {qrcode && <img src={`data:image/png;base64,${qrcode}`} alt="QR Code" className='w-36 h-36' />}
              </div>
              <p className='mt-2 text-sm text-center'>Can't scan QR? Use below code in app.</p>
              {/* Security Code */}
              <div className='flex items-center my-2 space-x-3 justify-center'>
                <span className='text-sm'>{secretKey}</span>
                <svg className="w-5 h-5 text-primary-700" aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 20' onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"d="m7.708 2.292.706-.706A2 2 0 0 1 9.828 1h6.239A.97.97 0 0 1 17 2v12a.97.97 0 0 1-.933 1H15M6 5v4a1 1 0 0 1-1 1H1m11-4v12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 5h5.239A.97.97 0 0 1 12 6Z"/>
                </svg>
              </div>
              <div className='pt-4 border-t rounded-t space-y-4'>
                <span>Verification Code</span>
                <div>
                  {/*OTP input Box*/}
                  {otp.map((value, index) => {
                          return (
                            <input
                              key={index}
                              type="text"
                              autoFocus={index === 0}
                              ref={(input) => (inputRefs.current[index] = input)}
                              value={value}
                              onChange={(e) => handleChange(index, e)}
                              onClick={() => handleClick(index)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              className="w-12 h-12 outline-none text-center border rounded-md shadow-sm focus:border-primary-500 mx-2"
                            />
                          );
                        })}                  
                </div>
                {/* Onclcik -> Contact */}
                <div className='flex justify-center'>
                  <Link to='/contact' target="_blank" className='text-primary-400 font-semibold'>Do you need help?</Link>
                </div>
              </div>
              {/* Button */}
              <button onClick={TwoFactorEnable} className="text-white w-full bg-sky-400 outline-none font-medium rounded-lg py-2.5 mt-4 text-center">
                Next
              </button>
            </div>
          </div>
          {/* Toast */}
          {isErrorToast && (
            <ErrorToast toastMessage={toastMessage}/>
          )}
          {isSuccessToast && (
            <SuccessToast toastMessage={toastMessage}/>
          )}
        </div>
      </div>
  );
}
