import React from 'react';
import Chart from 'react-apexcharts';

const Areachart = ({areaData}) => {
  const series = [
    {
      name: 'Queries',
      data: areaData.map(item => [
        new Date(item[0]).getTime(),
        item[1]
      ]),
    },
  ];

  const options = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    title: {
      text: 'DNS Traffic Distribution',
      align: 'left',
      style: {
        fontSize:  '20px',
        fontWeight:  'bold',
        fontFamily: 'sans-serif',
        color:  '#263238'
      },
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };

    return (
      // Area Chart
        <div id="chart-timeline" className='w-full h-full bg-white px-2 pb-2 pt-3 rounded-md shadow-default'>
          <Chart options={options} series={series} type="area" height={370} />
        </div>
    );
  }

export default Areachart;
