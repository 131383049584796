import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import CountUp from 'react-countup';

const Combinerchart = ({mcount,category,categorycount,records,recordcount}) => {
  
  const pieChart2Ref = useRef(null);
  const donutChartRef = useRef(null);

  useEffect(() => {
    // Donut Chart Options
    const donutChartOptions = {
      series: categorycount,
      colors: ["#4ade80", "#d946ef", "#ef4444", "#facc15","#c026d3","#3b82f6"],
      chart: {
        height: 230,
        width: "90%", 
        type: "donut",
      },
      stroke: { colors: ["transparent"], lineCap: "" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Total Count",
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return `${sum}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
            size: "70%",
          },
        },
      },
      onItemClick: {
        toggleDataSeries: true
    },
      grid: { padding: { top: -2 } },
      labels: category,
      dataLabels: { enabled: false },
      legend: { position: "right", fontFamily: "Inter, sans-serif" },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
        axisTicks: { show: false },
        axisBorder: { show: false },
      },
    };

    // Pie Chart Options
    const donutChartOptions2 = {
      series: recordcount,
      chart: {
        height: 230,
        width: "90%", 
        type: "donut",
      },
      stroke: { colors: ["transparent"], lineCap: "" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                label: "Total Count",
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return `${sum}`;
                },
              },
              value: {
                show: true,
                fontFamily: "Inter, sans-serif",
                offsetY: -20,
                formatter: function (value) {
                  return value;
                },
              },
            },
            size: "70%",
          },
        },
      },
      grid: { padding: { top: -2 } },
      labels: records,
      dataLabels: { enabled: false },
      legend: { position: "right", fontFamily: "Inter, sans-serif" },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
        axisTicks: { show: false },
        axisBorder: { show: false },
      },
    };

    if (
      document.getElementById("donut-chart2") &&
      document.getElementById("donut-chart") &&
      typeof ApexCharts !== "undefined"
    ) {
      if (!donutChartRef.current) {
        const donutChart = new ApexCharts(
          document.getElementById("donut-chart"),
          donutChartOptions
        );
        donutChart.render();
        donutChartRef.current = donutChart;
      }

      if (!pieChart2Ref.current) {
        const pieChart2 = new ApexCharts(
          document.getElementById("donut-chart2"),
          donutChartOptions2
        );
        pieChart2.render();
        pieChart2Ref.current = pieChart2;
      }
    }

    return () => {
      if (pieChart2Ref.current) {
        pieChart2Ref.current.destroy();
        pieChart2Ref.current = null;
      }
      if (donutChartRef.current) {
        donutChartRef.current.destroy();
        donutChartRef.current = null;
      }
    };
  }, [category, categorycount, recordcount, records]);

  return (
    <div className="flex justify-center items-center bg-slate-100 max-h-max">
      <div className="flex space-x-5 w-full">
        {/* pie Chart 1 */}
      <div
      className="flex flex-col w-[28%] py-10 overflow-hidden bg-white bg-gradient-to-br from-purple-400 via-blue-400 to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-default group">
      <div className="flex flex-row justify-center text-gray-100">
        <p className='text-4xl text-center mt-10 font-semibold'>Total Count</p>
      </div>
      <h1 className="text-6xl py-6 font-bold  text-center mt-2 text-gray-50">
        <CountUp start={0} end={mcount} delay={0}/>
      </h1>
    </div>
    {/* pie Chart 2 */}
        <div className="pb-3 w-[37%] bg-white rounded-md shadow-default">
          <h5 className="text-lg font-bold text-gray-900 py-2.5 pl-5">
            Count By Category
          </h5>
          <hr className="border-slate-300" />
          {category.length > 0 ? (
          <div id="donut-chart" className="my-2"></div>
          ) : (
            <p className="text-gray-800 text-center py-28 font-semibold">
            No data
          </p>
          )}
        </div>
        <div className="pb-3 w-[37%] bg-white rounded-md shadow-default">
          <h5 className="text-lg font-bold text-gray-900 py-2.5 pl-5">
            Count by records
          </h5>
          <hr className="border-slate-300" />
          {records.length > 0 ? (
          <div id="donut-chart2" className="my-2"></div>
          ) : (
            <p className="text-gray-800 text-center py-28 font-semibold">
            No data
          </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Combinerchart;
