import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import axios from '../API/axios';

export default function ProtectedRoutes() {
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/api/v1/auth/state", { withCredentials: true }
    ).then(function (response) {
        if (response.data.message === 'verify two factor authentication' && response.data.twofactor === true && response.status === 200){
          setAuth({'token':false});
        } else if (response.data.message === 'please set new password' && response.data.first_login === true && response.status === 200){
          setAuth({'token':false});
        } else if(response.data.message === 'user is authenticated' && response.status === 200){  
          setAuth({'token':true});
        }
      }).catch(function (error) {
        if (error.response){
          if (error.response.data.message === 'unauthorized access' && error.response.status === 401) {
            setAuth({'token':false});
          }else if (error.response.data.error === 'authorization_header' && error.response.data.message === 'request does not contains a valid token'){
            setAuth({'token':false});
          } else if (error.response.data.error === 'token_expired' && error.response.data.message === 'token is expired'){
            setAuth({'token':false});
          } else{
            setAuth({'token':false});
          }
        } else{
          setAuth({'token':false});
        }
      });
  }, [navigate]);

  if (auth === null) {
    return ("/");
  }

  return (
    auth.token ? <Outlet/> : <Navigate to='/'/>
  );
}
